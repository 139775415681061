.loyalty-success-modal{
	width: 585px;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 210px;
        margin: auto;
        margin-top: 60px;
	}
	.second-section-modal{
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            color: $white;
        }

        .description-modal {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: $white;
        }

        .coin-gained-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;

            .coin-gain {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                text-transform: uppercase;
                color: $white;
                margin: 0;
            }

            .euro-gain {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: #D5DDEC;
                margin: 0;
            }
        }

		.button-container{

            text-align: center;
            color: $white;
            margin-bottom: 35px;
            
            p {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
		}
	}
}
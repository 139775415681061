.bat-minicart-gloit {
	background-color: $concrete;
	border: 1px solid $dark-gray-50;
	bottom: 0;
	height: auto;
	margin-bottom: 0;
	max-height: none;
	position: fixed;
	right: 0;
	top: 0;
	z-index: $modal-zindex;
    width: 90%;
	overflow-y: auto;
	-ms-overflow-style: none;
  	scrollbar-width: none; 

	&::-webkit-scrollbar {
		display: none;
	}

	@include media-breakpoint-up(md) {
		max-width: 540px;
	}

	&-count {
		@include font-rem(20px, 24px);
        font-family: $primary-font;
        text-transform: uppercase;
        font-weight: 800;
		padding: 30px 0 22px;
		text-align: center;
		padding-inline: 12px;

		@include media-breakpoint-up(md) {  
            @include font-rem(22px, 28px);
            text-align: left;
			padding-inline: 48px;
		}
	}

	&-close {
		left: 12px;
		position: absolute;
		top: 28px;

		i {
			&:before {
				color: $brand-black;
				transition: color 0.5s ease;
			}
		}

		&:hover {
			i {
				&:before {
					color: $brand-black;
				}
			}
		}
	}

	&-wrapper {
		background-color: $concrete;
		padding: 0 0 60px;
		padding-inline: 12px;

		hr {
			border: 0;
			border-top: 1px solid $gray;
			margin-bottom: 20px;

			&.hidden{
				display: none;
			}
		}

		@include media-breakpoint-up(md) {
			padding-inline: 48px;
		}
	}

	&-buttons {
		display: block;

		@include media-breakpoint-up(sm) {
			display: block;
		}
	}

	&-button-edit, &-button-edit-checkout{
		margin-block: 25px 0;
		width: 100%;
		display: flex;
		justify-content: space-around;
		
		.bat-button {
			&.bat-cta-style {
				&.button-dark,
				&.button-secondary-dark {
					max-width: 100%;
					width: 100%;
				}

				&.button-secondary-dark {
					&:hover{
						span{
							color: $brand-black;
						}
					}
				}
			}
		}
	}

    &-subtotal-container{
        justify-content: space-between;
        align-items: center
    }

    &-subtotal-value-container{
        align-items: center
    }

	&-subtotal-value,
	&-subtotal-label {
		@include font-rem(16px, 20px);
		font-weight: 700;
		width: 49%;
        text-transform: uppercase
	}

	&-subtotal-label {
		@include font-rem(12px, 12px);
		letter-spacing: 1px;
		position: relative;
		top: -2px;
        text-transform: uppercase
	}

    &-subtotal-value{
        max-width: fit-content;
        @include font-rem(16px, 20px);
        font-weight: 600;
        font-family: $primary-font;
        margin-right: 5px
    }

    &-subtotal-coin{
        margin-right: 5px
    }

    &-subtotal-items{
        @include font-rem(14px, 20px);
        font-weight: 500;
    }

    &-warning-message{

		&.hidden{
			display: none;
		}

		@include media-breakpoint-down(sm){
			padding: 20px 10px;
		}

        &-icon{
            max-width: fit-content;
            margin: auto;
            margin-bottom: 15px;

            &::before{
                color: $red;
            }
        }

        &-title{
            @include font-rem(18px, 20px);
            font-weight: 700;
            font-family: $primary-font;
            color: $red;
        }

        &-text{
            @include font-rem(14px, 20px);
            font-family: $primary-font;
            padding-top: 15px;
            padding-bottom: 15px;
        }

		&-buttons{
			justify-content: center;

			.bat-button--dark{
				width: 100%;
				margin-right: 10px;
			}

			.bat-button--secondary--dark{
				width: 100%;
				margin-left: 10px;

				&:hover{
					color: $brand-black;
				}
			}
		}

		&::after{
			display: none;
		}

		&.active {
			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $light-gray;
				border-radius: 50%;
				border-top: 10px solid $purple;
				bottom: 0;
				content: "";
				height: 60px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 60px;
				display: block;
				z-index: 1;
			}

			opacity: 0.4;
			pointer-events: none;
		
		}
    }

	&-delivery-message{
		justify-content: center;
		background: $blue;
		color: $white;
		padding: 10px;
		text-transform: uppercase;
		font-weight: 800;
		@include font-rem(12px, 14px);
		letter-spacing: 1.2px;
		margin-bottom: 20px;

		&-icon{
			padding-right: 20px;
			@include media-breakpoint-down(sm) {
				padding-left: 20px;
			}
		}

		&-text{
			align-self: center;
		}
	}

	&-cart-item-container {
		margin-top: 0;
		position: relative;

		&.active {
			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $light-gray;
				border-radius: 50%;
				border-top: 10px solid $purple;
				bottom: 0;
				content: "";
				height: 60px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 60px;
				z-index: 1;
			}

			.bat-minicart-gloit-cart-item {
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}

	&-cart-item {
		align-items: center;
		background-color: $white;
		margin-bottom: 5px;
		opacity: 1;
		transition: opacity 0.5s ease;
		padding: 20px;

		@include media-breakpoint-down(sm){
			padding: 10px;
		}

		picture {
			width: 120px;
			height: 120px;

			@include media-breakpoint-down(sm){
				width: 80px;
				height: 80px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&-product-container {
			width: 70%;
			padding-left: 10px;

			&-infos {
				justify-content: space-between;
				align-items: baseline;
				.remove-item {
					margin-left: 5px;
					i {
						font-family: 'Font Awesome 5 Free';
					}
				}
			}

			&-title {
				@include font-rem(20px, 20px);
				color: $brand-black;
				display: flex;
				font-weight: normal;
				margin-bottom: 20px;

				@include media-breakpoint-down(sm){
					@include font-rem(14px, 17px);
					margin-bottom: 15px;
				}
			}

			&-strength {
				@include font-rem(14px, 20px);
				color: $dark-gray;
			}

			&-subscription{
				&-label{
					@include font-rem(12px, 14px);
					color: $blue;
					margin-left: 10px;
					text-transform: uppercase;
					font-weight: 800;
					letter-spacing: 1.2
				}
			}

			&-price {
				margin-top: 5px;

				&-quantity{
					@include font-rem(12px, 15px);
					font-weight: 800;
					text-transform: uppercase;
					align-self: center
				}

				&-container{
					&-productPrice{
						@include font-rem(16px, 20px);
						align-self: center;
						font-weight: 600;
						letter-spacing: 0.2px;
						margin-right: 5px
					}
				}
			}

			&-quantity-label {
				@include font-rem(12px, 12px);
				color: $dark-blue;
				display: block;
				font-weight: bold;
				margin-bottom: 2px;
				width: 100%;
			}

			&-quantity {
				margin-top: 10px;

				.update-item {
					margin-left: 20px;
					margin-top: 0;
					width: auto;

					span {
						@include font-rem(12px, 12px);
						color: $purple;
						transition: color 0.5s ease;
					}

					&:hover {
						span {
							color: $purple-hover;
						}
					}
				}
			}

			&-delivery {
				@include font-rem(12px, 12px);
				margin-bottom: 20px;

				span {
					font-weight: 700;
				}
			}

			&-option {
				@include font-rem(14px, 14px);
				color: $dark-gray;
				margin-bottom: 10px;

				h6 {
					@include font-rem(12px, 12px);
				}

				p {
					@include font-rem(12px, 12px);
				}
			}

			&-price{
				justify-content: space-between;
			}
		}
	}

	&-emptyCart{
		margin-top: 20px;
	}
}

.minicart-carousel{
	background-color: $white;
	padding: 30px;

	@include media-breakpoint-down(sm){
		padding: 30px 0px;
	}

	.carousel-label{
		@include media-breakpoint-down(sm){
			display: block;
			text-align: center;

			.label{
				display: block;
			}
		}

		.label{
			@include font-rem(26px, 30px);
			font-weight: 800;

			@include media-breakpoint-down(sm){
				@include font-rem(20px, 24px);
				margin-bottom: 10px
			}
		}

		.bat-cta-style{
			@include font-rem(16px, 28px);
			font-weight: 800;

			@include media-breakpoint-down(sm){
				@include font-rem(14px, 28px)
			}
		}
	}

	.bat-carousel{
		margin-bottom: 0px;

		.slides-container{
			padding: 60px 0px;

			@include media-breakpoint-down(sm){
				padding: 60px 0px;
			}

			.bat-productcard-gloithome{
				margin: auto;

				.cart-link-container{
					border: none;
					margin-top: 0px;
					padding-top: 0px;
				}

				.text{
					@include font-rem(16px, 20px);

					@include media-breakpoint-down(sm){
						@include font-rem(13px, 16px)
					}
				}
			}

			.navigation-arrows{
				width: 100%;

				@include media-breakpoint-down(sm){
					display: none;
					width: 95%;
					margin-left: 10px;
				}
			}
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

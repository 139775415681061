.bat-salesforceregistration-gloit{
    margin-inline: auto;
    max-width: 500px;

    @include media-breakpoint-down(md){
        margin-inline: 0px;
    }

    &-tabs{
        flex-direction: column-reverse;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        .tabs{
            justify-content: center;

            .tab{
                width: 100%;
                @include font-rem(13px, 15px);
                font-weight: 700;
                color: #6B6B6B;
                font-family: $primary-font;
                text-transform: uppercase;
                padding: 20px;
                border-bottom: 2px solid #DEDEDE;

                @include media-breakpoint-down(md){
                    @include font-rem(11px, 13px);
                    background-color: #EFEFEF;
                    border-bottom: none;
                }

                &.active{
                    color: #CD4A04;
                    border-bottom: 2px solid #CD4A04;
                }
            }
        }

        .title{
            @include font-rem(30px, 34px);
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 70px;
    
            @include media-breakpoint-down(md){
                @include font-rem(20px, 28px);
                margin-bottom: 30px;
            }
        }
    }

    &-form{
        margin-top: 40px;

        @include media-breakpoint-down(md) {
            margin-inline: 20px;
            margin-top: 0px;
        }

        .g-recaptcha{
            display: none;

            &.active{
                display: block;
            }
        }

        .bat-form-block{
            display: none;

            &.active{
                display: block;

                &.submit{
                    display: flex;
                    margin-top: 10px;
                }
            }

            .bat-form-container{
                &-fullname,
                &-date,
                &-idcode, 
                &-address,
                &-city,
                &-nation,
                &-email,
                &-password,
                &-product-choice{
                    display: flex;
                    
                    div{
                        input{
                            border-radius: 0px;
                        }
                    }

                    label{
                        &::after{
                            content: '*';
                            color: red;
                        }
                    }
                }

                &-date,
                &-city,
                &-nation,
                &-password,
                &-product-choice{
                    .bat-form--{
                            &-genre,
                            &-province,
                            &-nation,
                            &-product-choice-1,
                            &-product-choice-2,
                            &-product-choice-3{
                                .select-wrapper{
                                    margin-inline: 0px;

                                    select{
                                        -webkit-appearance: none;
                                        -webkit-border-radius: 0;
                                        border-radius: 0;
                                    }
                                }
                        }
                    }

                    @include media-breakpoint-down(md){
                        div:first-of-type{
                            margin-right: 10px;
                        }
    
                        div:last-of-type{
                            margin-left: 10px;
                        }

                        .bat-form-field{
                            input{
                                -webkit-appearance: none;
                            }       
                        }
                    } 
                }

                &-date{
                    .bat-form---genre{
                        label{
                            &::after{
                                content: '';
                            }
                        }
                    }

                    .bat-form---date{
                        .error-msg{
                            display: none;
                        }

                        .bat-icon{
                            margin-right: 10px;
                            margin-left: auto;
                            margin-top: -38px;
                            cursor: pointer;
                        }
                    }

                    input[type="date"]::-webkit-calendar-picker-indicator{
                        display: none;
                    }

                    input::-webkit-date-and-time-value {
                        text-align: left;
                        vertical-align: middle;
                    }

                    .bat-icon{
                        &::before{
                            content: url('/content/dam/glo-it/images/icon-calendar.svg');
                        }
                    }

                    input{
                        color: transparent;
                    }
                }

                &-fullname,
                &-id,
                &-address,
                &-email,
                &-password,
                &-product-choice{
                   @include media-breakpoint-down(md){
                        display: block;

                        div:first-of-type{
                            margin-right: 0px;
                        }

                        div:last-of-type{
                            margin-left: 0px;
                        }
                   } 
                }

                &-email,
                &-password{
                    .bat-form---password{
                        .error-msg{
                            display: none;
                        }

                        .bat-icon{
                            margin-right: 10px;
                            margin-left: auto;
                            margin-top: -34px;
                            cursor: pointer;
                        }
                    }
                }

                &-email{
                    .password-meter{
                        border: 2px solid $concrete;
                        @include font-rem(14px, 20px);
                        margin: 0px;
                        margin-top: 5px;
                        margin-right: 0px !important;
                        padding: 5px 10px;
        
                        &.no-password{
                            background: $white
                        }
        
                        &.weak{
                            background: -webkit-linear-gradient(left, #ffafae 25%, white 25%);
                            background: -moz-linear-gradient(left, #ffafae 25%, white 25%);
                            background: -o-linear-gradient(left, #ffafae 25%, white 25%);
                            background: -ms-linear-gradient(left, #ffafae 25%, white 25%);
                            background: linear-gradient(left, #ffafae 25%, white 25%);
                        }
        
                        &.medium{
                            background: -webkit-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: -moz-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: -o-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: -ms-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: linear-gradient(left, #ffd6b3 50%, white 50%);                    
                        }
        
                        &.strong{
                            background: -webkit-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: -moz-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: -o-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: -ms-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: linear-gradient(left, #c5eeac 75%, white 75%);
                        }
        
                        &.very-strong{
                            background: -webkit-linear-gradient(left, #81b562 100%, white 100%);
                            background: -moz-linear-gradient(left, #81b562 100%, white 100%);
                            background: -o-linear-gradient(left, #81b562 100%, white 100%);
                            background: -ms-linear-gradient(left, #81b562 100%, white 100%);
                            background: linear-gradient(left, #81b562 100%, white 100%);
                        }
                    }
                }

                &-password{
                    .password-meter{
                        display: none;
                    } 
                }

                &-id{
                    .bat-form---friend-code{
                        label{
                            &::after{
                                content: '';
                            }
                        }
                    }
                }

                &-city{
                    .bat-form---province{
                        label{
                            &::after{
                                content: '';
                            }
                        }
                    }
                }

                &-continue{
                    margin-top: 30px;
                    text-align: right;
                    display: flex;

                    button{
                        width: 220px;

                        @include media-breakpoint-down(md){
                            width: 95%;
                            padding-inline: 14px;
                        }
                    }

                    .bat-form---back{
                        text-align: left;

                        button{
                            border-color: #6B6B6B;
                            color: #6B6B6B;
    
                            &:hover{
                                border-color: #6B6B6B;
                                
                                span{
                                    color: #6B6B6B; 
                                }
                            }
                        }
                    }

                    &.first-section{
                        button{    
                            @include media-breakpoint-down(md){
                                width: 45%;
                            }
                        } 
                    }
                }

                &-more{
                    .bat-form---more{
                        textarea{
                            resize: none;
                        }
                    }

                    &.hidden{
                        display: none;
                    }
                }

                &-checkbox-1,
                &-checkbox-2{
                    padding: 20px;
                    border: 1px solid #DEDEDE;
                }

                &-checkbox-1{
                    margin-bottom: 25px;

                    .bat-form---policy-checkbox-1,
                    .bat-form---policy-checkbox-2,
                    .bat-form---newsletter-checkbox,
                    .bat-form---contact-checkbox{
                        margin-bottom: 0px;

                        fieldset{
                            margin-bottom: 0px;

                            label{
                                margin-bottom: 0px;

                                input{
                                    &::before{
                                        color: #C4C4C4;
                                    }
                                }

                                span{
                                    font-size: 12px;
                                    padding-bottom: 0px;
                                    color: $brand-black;
                                }
                            }
                        }
                    }
                    
                    .bat-form---newsletter-checkbox{
                        fieldset{
                            label{
                                input{
                                    display: none;
                                }

                                span{
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }

                    .bat-form---contact-checkbox{
                        fieldset{
                            display: flex;

                            label{
                                margin-right: 20px;
                            }
                        }
                    }

                    .bat-form---policy-checkbox-1,
                    .bat-form---contact-checkbox{
                        fieldset{
                            label{
                                span{
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }

                &-checkbox-2{
                    .bat-form---policy-checkbox-1,
                    .bat-form---policy-checkbox-2{
                        margin-bottom: 0px;

                        fieldset{
                            margin-bottom: 0px;

                            label{
                                margin-bottom: 0px;

                                input{
                                    &::before{
                                        color: #C4C4C4;
                                    }
                                }

                                span{
                                    font-size: 12px;
                                    padding-bottom: 0px;
                                    color: $brand-black;
                                }
                            }
                        }
                    }

                    .bat-form---policy-checkbox-1{
                        fieldset{
                            label{
                                span{  
                                    margin-bottom: 15px;
                                } 
                            }
                        }
                    }
                }
            }

            &.submit{
                .bat-form--salesforceregistration-back,
                .bat-form--salesforceregistration-submit{
                    width: fit-content;

                    button{
                        width: 220px;
                    }

                    @include media-breakpoint-down(md){
                        width: 47%;

                        button{
                            width: 100%;
                            padding-inline: 0px;
                        }
                    }
                }

                .bat-form--salesforceregistration-submit{
                    text-align: right;
                }

                .bat-form--salesforceregistration-back{
                    button{
                        border-color: #6B6B6B;
                        color: #6B6B6B;

                        &:hover{
                            border-color: #6B6B6B;

                            span{
                                color: #6B6B6B; 
                            }          
                        }
                    }
                }
            }

            .bat-form--salesforceregistration-messages {
                .error-msg {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
    }
}
.order-details{
	max-width: 980px;
	padding-bottom: 40px;
	
	.track-delivery{
		@include media-breakpoint-up(lg) {
			margin-left: 10px;
		}
		@include media-breakpoint-down(lg) {
			margin-top: 10px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.container-details{
		margin-top: 30px;
		margin-bottom: 30px;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			.uk-flex-bottom {
				align-items: baseline !important;
			}	
		}
		.container-columns{
			@include media-breakpoint-down(lg) {
				margin-left: 20px;
			}
		}
	}
	.button-buy-again{
		font-weight: 800;
		font-size: 14px;
		line-height: 28px;
		@include media-breakpoint-down(lg) {
			text-align: center;
		}
		.button-light{
			@include media-breakpoint-down(lg) {
				padding: 10px 80px;
			}
		}
	}
}

.order-details-tabs{
	max-width: 980px;

	.tab{
		width: 100%;
		border-bottom: 2px solid #efefef;
		@include font-rem(13px, 15px);
		text-transform: uppercase;
		padding-bottom: 20px;
		font-family: $primary-font;
		font-weight: 700;
		color: #6B6B6B;

		@include media-breakpoint-down(lg){
			@include font-rem(11px, 13px);
		}

		&.active{
			border-bottom: 2px solid #CD4A04;
			color: #CD4A04;
		}
	}
}

.print-invoices-button-container,
.print-shipments-button-container{
	&.hidden{
		display: none;
	}
}

.print-invoices-button-container{
	max-width: 980px;
	margin-top: 30px;

	@include media-breakpoint-down(lg){
		text-align: center;

		.print-invoices-button{
			width: 250px;
			padding-inline: 0px;
		}
	}
}


.print-shipments-button-container{
	margin-top: 30px;
	max-width: 980px;

	@include media-breakpoint-down(lg){
		text-align: center;

		.print-shipments-button{
			width: 250px;
			padding-inline: 0px;
		}
	}
}

.order-title-container,
.invoice-title-container,
.shipment-title-container{
	margin-top: 30px;
	margin-bottom: 30px;

	&.hidden{
		display: none;
	}
}

.order-title-container{
	margin-top: 30px;
	margin-bottom: 30px;
	max-width: 980px;

	@include media-breakpoint-down(lg){
		display: block;
		text-align: center;
	}

	.title-manageable-slot{
		align-self: center;
	}

	.print-order-button{
		margin-top: 0px;

		@include media-breakpoint-down(lg){
			margin-top: 20px;
			width: 250px;
		}
	}	
}

.invoice-title-container{
	margin-top: 30px;
	margin-bottom: 30px;
	max-width: 980px;

	@include media-breakpoint-down(lg){
		display: block;
		text-align: center;
	}

	.title-manageable-slot{
		align-self: center;
	}

	.print-invoice-button{
		margin-top: 0px;

		@include media-breakpoint-down(lg){
			margin-top: 20px;
			width: 250px;
		}
	}	
}

.shipment-title-container{
	margin-top: 30px;
	margin-bottom: 30px;
	max-width: 980px;
	
	@include media-breakpoint-down(lg){
		display: block;
		text-align: center;
	}
	
	.title-manageable-slot{
		align-self: center;
	}

	&.shipment{
		align-self: center;
	}

	.print-shipment-button{
		margin-top: 0px;

		@include media-breakpoint-down(lg){
			margin-top: 20px;
			width: 250px;
		}
	}	

	.shipment-buttons-container{

		@include media-breakpoint-down(lg){
			display: flex;
			margin-top: 30px;
			justify-content: space-between;
		}

		.print-shipment-button,
		.track-shipment-button{
			width: 250px;

			@include media-breakpoint-down(lg){
				padding-inline: 0px;
				font-size: 11px;
				width: 47%;
			}
		}

		.track-shipment-button{
			padding-inline: 0px;
		}
	}
}

.title-manageable-slot{
	@include media-breakpoint-down(lg) {
		text-align: center;
	}

	&.hidden{
		display: none;
	}
}

.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--9 {
    width: 75%;
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
}
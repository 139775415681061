.item-details{
	@include media-breakpoint-down(lg) {
		padding: 30px;
		
	}

	&.hidden{
		display: none;
	}

	.container-info-prod{
		.button-secondary-light {
			color: $brand-black;
			padding: 10px 50px;
		}
		margin-left: inherit;
		.uk-grid-large{
			margin-top: 0;
			margin-left: 0;
		}
		.uk-grid{
			margin-top: 0;
			margin-left:0;
		}
		max-width: 980px;
		padding-top: 30px;
		padding-bottom: 30px;
		border-bottom: 2px solid $concrete;
		.img-product{
			max-width: 82px;
			max-height: 74px;
			margin-right: 40px;
		}
		.name-product-container{
			@include media-breakpoint-up(lg){
				margin-bottom: 10px;
			}
		}

		.selected-options{
			justify-content:space-between;
			gap: 30px;
		}
		.name-product{
			font-weight: 500;
			font-size: 15px;
			line-height: 30px;
		}
		.additional-info{
			font-weight: 500;
			font-size: 13px;
			line-height: 140%;
		}
		.additional-info-label{
			font-weight: 500;
			font-size: 13px;
			line-height: 140%;
			margin-right: 2px;
			font-weight: bold;
		}
		.quantity{
			font-weight: 600;
			font-size: 13px;
			line-height: 125%;
			@include media-breakpoint-down(lg){
				margin-top: 30px !important;
				margin-left: 29%;
			}
		}
		.container-order-code{
			@include media-breakpoint-down(lg){
				margin-top: 20px !important;
			}
		}
		.order-info{
			font-size: 15px;
			line-height: 125%;
			
		}
		.price{
			font-weight: 700;
			font-size: 15px;
			line-height: 20px;
			@include media-breakpoint-down(lg){
				margin-top: 10px !important;
				margin-left: 29%;
			}
		}
		.out-of-stock{
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: $red;
		}
	}
}

.section-total{
	max-width: 980px;
	padding-top:30px;
	margin-bottom: 30px;
	@include media-breakpoint-down(lg) {
		padding: 30px;
	}

	&.hidden{
		display: none;
	}

	.second-section{
		max-width: 550px;
		background-color: $concrete;
		padding-left:25px;
		padding-right:25px;
		.writing-total{
			padding-top: 20px;
			border-bottom: 2px solid $brand-black;
			.second-column{
				padding-left: 180px;
				@include media-breakpoint-down(lg) {
					padding-left: 58px;
				}

			}
			.element-total{
				display: block;
				margin-top: 10px;
				margin-bottom: 10px;
				font-weight: 700;
				font-size: 12px;
				line-height: 115%;
			}
			.sale{
				color: $blue;
			}
		}
		.total-count{
			display: flex;
			justify-content: space-between;
			padding-top: 30px;
			padding-bottom: 20px;
			.total-price{
				padding-left: 170px;
				@include media-breakpoint-down(lg) {
					padding-left: 100px;
				}
			}
			
		}
	}
	
}

.shipment-info-container{
	max-width: 980px;

	&.hidden{
		display: none;
	}

	.shipment-info-table{
		thead{
			tr{
				border-bottom: 2px solid #efefef;
				th{
					text-transform: capitalize;
					color: $brand-black;
					font-family: $primary-font;
					font-weight: 700;
				}
			}
		}

		tbody{
			tr{
				border-bottom: 2px solid #efefef;

				td{
					border: none;
				}
			}
		}
	}

	@include media-breakpoint-down(lg){
		justify-content: space-between;
		border-bottom: 2px solid #efefef;
		padding-bottom: 10px;
		padding-top: 10px;
		margin-inline: 20px;

		.info-label,
		.info-data{
			display: grid;
		}

		.info-label{
			.label{
				text-transform: capitalize;
				font-weight: 700;
			}
		}
	}
}
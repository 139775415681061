.mgm-modal{
	width: 585px;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
	
    .uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal {		
        margin-top: 60px;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }
        
        .mgm-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 8px;
            background: #0B1047;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            .icon-mgm {
                height: 48px;
                width: 48px;

                &::before {
                    transform: scale(2.4);
                    height: 38px;
                }
            }
        }
	}

	.second-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: $white;
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
        }

	}

    .third-section-modal {

        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 14px;
        padding-right: 14px;
        
        .mgm-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 40px;
            border: 3px solid $white;
            font-weight: 700;
            font-size: 14px;
            font-family: 'Mont';
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: $white;
            
            &.active {
                background: $white;
                color: #2A2C2C;
            }
        }

        .mgm-box-email {
            border-radius: 24px 0px 0px 24px;
            padding: 10px 16px 10px 32px;

            @include media-breakpoint-down(md) {
                padding: 10px 3px 10px 3px;
            }
        }

        .mgm-box-whatsapp {
            border-radius: 0px 24px 24px 0px;
            padding: 10px 32px 10px 16px;

            @include media-breakpoint-down(md) {
                padding: 10px 3px 10px 3px;
            }
        }
    }

    .fourth-section-modal {

        .mgm-container {
            display: none;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            padding-left: 24px;
            padding-right: 24px;
            max-width: 457px;

            &.active {
                display: flex;
            }

            &-email {
                margin-bottom: 24px;
                color: $white;
                text-align: center;

                &-title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                }

                &-code {
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 30px;
                }

                &-description {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            &-whatsapp {
                margin-bottom: 24px;
                color: $white;
                text-align: center;

                &-title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                }

                &-description {
                    padding: 16px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #2A2C2C;
                }
            }
        }

        .cta-mgm {
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 32px 10px 29px;
            gap: 8px;

            @include media-breakpoint-down(md) {
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;

                span {
                    color: $brand-black;
                    text-decoration: none;
                }
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }

            i::before {
                font-size: 20px;
            }
        }
    }
}
.bat-tabs-gloit {
	border-top: solid 12px $gray;
	position: relative;
	
	&-indicator {
		border-color: $gray transparent transparent;
		border-style: solid;
		border-width: 12px 7px 0;
		height: 0;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: left .25s ease-in, opacity .5s ease-in;
		width: 0;
		
		&.active {
			opacity: 1;
		}
		
	}

	&-list {
		list-style: none;
		margin: 12px 0 0;
		padding: 0;

		&-item {
			flex: 0 0 auto;
			margin: 0;
            padding-left: 0px;
	
			&-button {
				align-items: center;
				display: flex;
				padding: 10px;
                justify-content: center;
                width: 100%;
				
				i {
					height: 16px;
					margin-left: 20px;
					width: 16px;
					
					&:before {
						font-size: 16px;
					}
				}
				
				.active & {
					border-bottom: solid 1px $gray;
				}
			}
		}
	}
	
	&-panels {
		
		&-panel {
			display: none;
			overflow: hidden;
			
			.authorEditMode & { // sass-lint:disable-line class-name-format
				display: block;
			}

			&.active {
				display: block;
			}
			
			& > div {
				padding: 20px 0;
			}
		}
	}
}

body {
	.bat-wrapper {
		bat-tabs-gloit {
			padding-bottom: 40px;

			bat-sectiondefault-gloit {
				.card {
					display: none;
					&:first-child {
						display: block; // seems to fix weird Tabs bug where multiple Cards are showing
					}
				}
			}

			&.bat {
				@include media-breakpoint-up(lg) {
					height: 525px;
					margin-left: -3.5px;
					min-height: 500px;
					padding: 0px 100px
				}
			}

			.bat-tabs-gloit {
				border-top: 0;

				&-list {
					justify-content: center;
					margin-top: 0;

					&-item {
						&-button {
							border-bottom: 2px solid $light-gray;
							box-shadow: none;

							padding: 12px;

							@include media-breakpoint-up(md) {
								padding: 12px 20px;
							}

							@include media-breakpoint-up(lg) {
								padding: 12px 30px;
							}

							span {
								color: $light-gray;
								font-family: $primary-font;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: 0.04rem;
								line-height: 14px;
								position: relative;
							}
						}
						&.active {
							button {
								border-bottom: 3px solid #EC7B2E;

								span {
									color: $black;

									@include media-breakpoint-up(lg) {
										color: $black;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

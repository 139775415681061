.product-listing-gloit {
	.promo {
		background-color: rgba($orange, 0.2);
		text-align: center;
		width: 100%;
		font-weight: 800;
		line-height: 13.75px;
		font-size: 11px;
		padding: 7px 0;
	}
	.bat {
		max-width: none !important;
	}

	.price-rating{
		padding-bottom: 0;
		width: 100%;
	}
		.review-container{
			padding-bottom: 0;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				padding-top:20px;
			}
		
			.bat-icon{
				height: 13px;
				width: 13px;
				&::before{
					font-size: 13px;
				}
				
			}
			.number-review{
				margin-left: 5px;
				font-size: 12px;
				margin-top: -4px;
			}
		}
	
	bat-productlisting-gloit {
		.bat-productlistings {
			max-width: unset;
	
			&--glo-brochureware {
				background-color: #e5e5e5;
				display: flex;
				flex-direction: row;
				margin: 0 auto;
				padding: 0 0 20px;
				position: relative;
				width: 100%;
	
				.bat-productlistings-card {
					background-color: $white;
					display: flex;
					flex-direction: column;
					height: 100%;
					margin: 10px 0;
					max-width: 100%;
					min-width: 100vw;
					min-height: unset;
					padding: 20px;
					padding-bottom: 35px;
					position: relative;
					width: 100%;
					overflow: visible;
	
					&-buttons {
						margin: auto 0 0;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
	
						button, a {
							margin-bottom: 0;
							max-width: unset;
							text-transform: uppercase;
							&:not(.arrow-link-dark) {
								width: 100%;
							}
							&.active {
								display: inline-block;
							}
						}

						.button-dark{
							padding: 10px;
							margin-top: 30px;
						}
					}
	
					&-description {
						margin-bottom: 12px;
						p {
							@include font-rem(13px, 125%);
							display: block;
							font-style: normal;
						}
					}
	
					&-details {
						align-items: stretch;
						display: flex;
						flex-direction: row;
						height: auto;
						margin: 0;
						max-height: unset;
						padding: 0;
						position: static;
						width: 100%;
						z-index: initial;
	
						& > div {
							display: flex;
							flex-direction: column;
							padding-top: 15px;
						}
	
						&-image {
							a {
								display: block;
								flex: 0 0 105px;
								picture {
									display: flex;

									height: 240px;
									width: 240px;

									@include media-breakpoint-down(sm) {
										height: 120px;
										width: 120px;
									}

									@include media-breakpoint-down(xs) {
										height: 80px;
										width: 80px;
									}
	
									> img {
										width: 100%;
										height: 100%;
										object-fit: contain;
									}
								}
							}
						}
	
						&-text {
							padding-left: 10px;
							flex-grow: 1;
						}
					}
	
					&-message {
						background-color: transparent;
						color: $purple;
						display: none;
						justify-content: center;
						padding: 5px;
	
						button {
							background-color: transparent;
							color: $error;
						}
	
						.message {
							padding: 0 1rem;
						}
					}
	
					&-name {
						@include font-rem(16px, 22px);
						color: $brand-black;
						border-bottom: 0;
						font-weight: 400;
						margin: 0 0 8px;
						padding: 0;
						text-align: left;
						a {
							text-decoration: none;
							color: inherit;
						}
					}
	
					&-options {
						display: flex;
						flex: 1 1 auto;
						flex-direction: column;
						text-align: left;
						width: auto;
	
						&-title {
							@include font-rem(12px, 16px);
							font-weight: 700;
							letter-spacing: 0;
							margin-bottom: 5px;
						}
						
						&-variants-button {
							$button-size: 34px;
							height: $button-size;
							width: $button-size;
							border-radius: 99999999px;
							padding: 4px;
							position:relative;
							&:after {
								content: "";
								background-color: var(--button-variant-color);
								display: block;
								width: 100%;
								height: 100%;
								border-radius: 99999999px;
							}
							&:active,
							&.active {
								border: 1px solid $brand-black;
							}
							&:disabled{
								
								background-color: var(--button-variant-color);
								border: 4px solid $white;
								&:active{
									border: 0;
								}
								&:after {
									background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
									background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									bottom: 0;
									content: '';
									
									left: 0;
									position: absolute;
									right: 0;
									top: 0;
								}
							}
						}
	
						p {
							display: none;
						}
					}
	
					&-strengths {
						&-oneline-container {
							display: flex;
							flex-direction: row;
							align-items: center;
							flex-wrap: wrap;
							margin-bottom: 15px;
						}
	
						&-container {
							display: flex;
							flex-wrap: wrap;
		
							.bat-indicator {
								border: none;
								align-items: baseline;
								flex-direction: row;
								font-family: $primary-font;
								justify-content: center;
								margin: 0;
								padding: 0;
								width: auto;
								$size: 13px;
								ul {
									li {
										width: $size;
										height: $size;
										line-height: $size;
										font-size: $size;
										border-color: $brand-black;
									}
								}
	
								&.strength-4mg,
								&.strength-mild,
								&.strength-1 {
									li {
										&:nth-child(1) {
											background-color: $brand-black;
										}
									}
	
									&.active {
										li {
											&:nth-child(1) {
												background-color: $white;
											}
										}
									}
								}
	
								&.strength-6mg,
								&.strength-medium,
								&.strength-2 {
									li {
										&:nth-child(-n + 2) {
											background-color: $brand-black;
										}
									}
	
									&.active {
										li {
											&:nth-child(-n + 2) {
												background-color: $white;
											}
										}
									}
								}
	
								&.strength-10mg,
								&.strength-strong,
								&.strength-3 {
									li {
										&:nth-child(-n + 3) {
											background-color: $brand-black;
										}
									}
	
									&.active {
										li {
											&:nth-child(-n + 3) {
												background-color: $white;
											}
										}
									}
								}
	
								&.strength-16mg,
								&.strength-x-strong,
								&.strength-4 {
									li {
										&:nth-child(-n + 4) {
											background-color: $brand-black;
										}
									}
	
									&.active {
										li {
											&:nth-child(-n + 4) {
												background-color: $white;
											}
										}
									}
								}
	
								&.strength-18mg,
								&.strength-ultra,
								&.strength-5 {
									li {
										&:nth-child(-n + 5) {
											background-color: $brand-black;
										}
									}
	
									&.active {
										li {
											&:nth-child(-n + 5) {
												background-color: $white;
											}
										}
									}
								}
	
								&.strength-20mg,
								&.strength-max,
								&.strength-6 {
									li {
										&:nth-child(-n + 6) {
											background-color: $brand-black;
										}
									}
	
									&.active {
										li {
											&:nth-child(-n + 6) {
												background-color: $white;
											}
										}
									}
								}
								span {
									@include font-rem(12px, 12px);
									color: $brand-black;
									font-weight: 700;
									margin-left: 10px;
									margin-bottom: 0;
								}
							}
						}
						&-label {
							display: inline-flex;
							flex-direction: row;
							align-items: center;
							margin: 0;
							margin-inline-end: 10px;
							font-weight: normal;
							@include font-rem(13px, 185%);
							width: fit-content;
							&-popover-button {
								.bat-icon:before {
									@include font-rem(19px, 100%);
								} 
							}
							&-popover-content {
								@include font-rem(10px, 150%);
								position: absolute;
								background-color: $charcoal;
								color: $white;
								padding: 15px;
								transform: translateX(50%);
								width: 200px;
								$triangle-size: 10px;
								display: none;
								margin-top: $triangle-size;
								z-index: 999;
	
								&[data-show] {
									display: block;
								}
	
								&-arrow {
									visibility: hidden;
	
									&, &::before {
										position: absolute;
										width: $triangle-size;
										height: $triangle-size;
										background: inherit;
									}
	
									&::before {
										visibility: visible;
										content: '';
										transform: rotate(45deg);
									}
								}
	
								&[data-popper-placement^='top'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
									bottom: #{-($triangle-size / 2)};
								}
							
								&[data-popper-placement^='bottom'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
									top: #{-($triangle-size / 2)};
								}
						
								&[data-popper-placement^='left'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
									right: #{-($triangle-size / 2)};
								}
						
								&[data-popper-placement^='right'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
									left: #{-($triangle-size / 2)};
								}
	
	
								p {
									display: block;
									font-size: inherit;
									line-height: inherit;
								}
							}
						}
					}
	
	
					&-prices {
						@include font-rem(18px, 18px);
						color: $black;
						font-weight: 700;
						margin-bottom: 10px;
						text-align: left;
						vertical-align: middle;
	
						.base-price {
							margin-right: 0.5rem;
	
							&.has-sale-price {
								@include font-rem(18px, 18px);
								color: $gray;
								position: relative;
								text-decoration: none;
	
								&:before {
									-moz-transform: rotate(0deg);
									-ms-transform: rotate(0deg);
									-o-transform: rotate(0deg);
									-webkit-transform: rotate(0deg);
									border-color: $gray;
									border-top: 2px solid;
									content: "";
									left: 0;
									position: absolute;
									right: 0;
									top: 50%;
									transform: rotate(0deg);
								}
							}
						}
					}
	
					&-quantity {
						margin: 0 0 0 auto;
						width: 90px;
					}
	
					&-stock {
						@include font-rem(20px, 20px);
						color: $purple;
						display: none;
						font-style: normal;
						padding: 0;
					}
	
					&-strengths {
						display: flex;
						flex-wrap: wrap;
						padding-bottom: 5px;
						button {
							&.bat-indicator {
								align-items: center;
								border-top-width: 0;
								flex-direction: row;
								font-family: $primary-font;
								justify-content: center;
								margin: 0;
								min-width: 95px;
								padding: 15px;
								width: 50%;
								&.full-width {
									width: 100%;
								}
								&:nth-child(even) {
									border-left: 0;
								}
								&:nth-child(1),
								&:nth-child(2) {
									border-top-width: 1px;
								}
								span {
									@include font-rem(10px, 10px);
									font-weight: 700;
									margin-bottom: 3px;
								}
							}
						}
					}
	
					&-variants {
						margin: 10px 0 20px auto;
						max-width: 45%;
	
						form {
							.select-wrapper {
								select {
									padding: 11px 30px 13px 13px;
									text-overflow: ellipsis;
								}
							}
						}
					}
				}
	
				.bat-productlistings-cards {
					max-width: 1200px;
				}
	
				.loading-wrapper {
					margin: 50px auto;
					align-items: center;
					display: flex;
					height: 110px;
					position: relative;
					width: 100px;
	
					p {
						text-align: center;
						width: 100%;
						font-size: 14px;
						margin: 0;
					}
	
					&:after {
						-webkit-animation: spin 2s linear infinite; /* Safari */
						animation: spin 2s linear infinite;
						border: 10px solid $light-gray;
						border-radius: 50%;
						border-top: 10px solid $purple;
						bottom: 0;
						content: "";
						height: 100px;
						left: 0;
						margin: auto;
						position: absolute;
						right: 0;
						top: 0;
						width: 100px;
						z-index: 1;
					}
				}
	
				.errors-wrapper {
					ul {
						display: none;
					}
				}
			}
		}

		.quantity-counter{
			.quantity-label{
			   @include font-rem(12px, 14px);
			   letter-spacing: 1.2px;
			   font-weight: 800;
			   font-family: $primary-font;
			   max-width: fit-content;
			   margin: auto;
			   margin-left: 0px;
			   margin-right: 35px;
	
			   @include media-breakpoint-down(sm){
				margin-right: 60px;
			   }
	
			   &.size{
				margin-right: 15px;
			   }
	
			   &.size-selected{
				   font-weight: 500;
				   font-size: 15px;
				   color: #646464;
			   }
			}
	
			.counter{
				button{
					width: 28px;
					height: 28px;
				}
				.icon-plus,
				.icon-minus{
					background-color: $concrete;
					border-radius: 100%;
					color: $brand-black;
					font-weight: 800;
					padding: 5px;
					&:before{
						font-weight: 800;
						font-size: 18px;
					}
				}
	
				.counter-number{
					@include font-rem(15px, 14px);
					letter-spacing: 1.2px;
					font-weight: 800;
					font-family: $primary-font;
					margin-left: 16px;
					margin-right: 16px;
				}
			}
		}
	}
	

	.bat-producthero-configurator-configurable-options {
		display: flex;
		flex-direction: row;
		text-align: left;
		width: auto;
		margin-bottom: 20px;

		.size-container{
			
			border-radius: 100%;
			margin-right: 2px;
			margin-top: 5px;
			border: 1px solid transparent;
			
			&.active{
				.size{
					border: 1px solid $brand-black;
					color: $brand-black;
				}
			}

			.size{
				border-radius: 100%;
				width: 30px;
				position: relative;
				height: 30px;
				background-color: $concrete;
				color: $dark-gray;
				font-weight: 600;
				font-size: 12px;
				font-family: $primary-font;
				margin: 2px;

				&:hover{
					border: 1px solid $brand-black;
					color: $brand-black;
				}
	
				&:active{
					background-color: $white;
					box-shadow: 0 0 3px #1d1d1d;
					border: 1px solid $white;
					background-color: $white;
					color: $brand-black;
					
				}
				&:disabled{
					&:hover{
						border: 0;
						color: $dark-gray;
					}
					&:active{
						background-color: $concrete;
						color: $brand-black;
						box-shadow: 0 0 0 #1d1d1d;
						color: $dark-gray;
					}
					&:after {
						height:23px;
						width:26px;
						background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
						background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						bottom: 0;
						content: '';
						
						left: 0;
						position: absolute;
						right: 0;
						top: 2;
					}
				}
			}

		}

		&-title {
			@include font-rem(12px, 16px);
			font-weight: 700;
			letter-spacing: 0;
			margin-top: 5px;
			margin-bottom: 5px;
		}
		
		&-variants-button {

			$button-size: 34px;
			height: $button-size;
			width: $button-size;
			border-radius: 99999999px;
			padding: 4px;
			margin-top: 5px;
			margin-bottom: 10px;
			&:after {
				content: "";
				background-color: var(--button-variant-color);
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 99999999px;
			}
			&:active,
			&.active {
				border: 1px solid $brand-black;
			}
			
		}

		p {
			display: none;
		}
	}


	
	@include media-breakpoint-up(sm) {
		.price-rating{
			order:4;
			margin-bottom: 0;
		}
		bat-productlisting-gloit {
			.bat-productlistings {
				&--glo-brochureware {
					.bat-productlistings-card {
						margin: 10px;
						min-width: 268px;
						min-height: 498px;
						width: calc(50% - 20px);
						padding-bottom: 20px;
						&:hover {
							.bat-productlistings-card-buttons {
								a {
									display: none;
								}
								button, a {
									&.active {
										display: inline-block;
									}
								}
							}
						}
					}
	
					.bat-productlistings-filter-count {
						display: block;
					}
	
					.bat-productlistings-card-details {
						border: 0;
						flex-direction: column;
						align-items: center;
						margin-bottom: 14px;
						padding: 0;
						& > div {
							padding: 0;
							width: 100%;
							justify-content: space-between;
						}
						&-image {
							a {
								flex: 1 0 100%;
								text-align: center;
	
								> picture {
									margin: 0 auto;
								}
							}
						}
						.bat-productlistings-card {
							&-name {
								margin-top: 20px;
								margin-bottom: 10px;
								order: 1;
							}
	
							&-description {
								order: 2;
							}
			
							&-strengths {
								padding-bottom: 15px;
							}
	
							&-options {
								order: 3;
							}
	
							
							&-buttons {
								padding-top: 15px;
								border-top: 2px solid $light-gray;
								margin-top: 15px;
								button, a {
									&.active {
										display: inline-flex;
									}
								}
								order: 5;
							}
						}
					}
	
				}
			}
		}
	}
	
	@include media-breakpoint-up(lg) {
		bat-productlisting-gloit {
			&.bat {
				// sass-lint:disable no-important
				max-width: unset !important;
			}
			.bat-productlistings {
				&--glo-brochureware {
					.bat-productlistings-card {
						min-width: 310px;
						width: calc(33.3% - 20px);
					}
				}
			}
		}
	}
	
	@include media-breakpoint-up(xl) {
		bat-productlisting-gloit {
			.bat-productlistings {
				&--glo-brochureware {
					.bat-productlistings-card {
						min-width: 280px;
						width: calc(25% - 20px);
						min-height: 498px;
					}
				}
			}
		}
	}
	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.rating {
		display: inline-block;
		unicode-bidi: bidi-override;
		color: #dedede;
		font-size: 14px;
		letter-spacing: 1px;
		height: 23px;
		width: auto;
		margin: 0;
		margin-top: -4px;
		position: relative;
		padding: 0;
		font-family: FontAwesome;
	}
	  
	.rating-upper {
		color: black;
		padding: 0;
		position: absolute;
		z-index: 1;
		display: flex;
		top: 0;
		left: 0;
		overflow: hidden;
		&::before {
			content: "\f005 \f005 \f005 \f005 \f005";
		}
	}
	  
	.rating-lower {
		padding: 0;
		display: flex;
		z-index: 0;
		&::before {
			content: "\f005 \f005 \f005 \f005 \f005";
		}
	}
}

//Variables
@import './global/variables';
@import './global/authoring';

//Grid
@import './grid/aem-grid';

//Base
@import './base/fonts';
@import './base/icomoon';
@import './base/typography';

//Partials
@import './partials/headline';
@import './partials/text';
@import './partials/links';
@import './partials/buttons';
@import './partials/form';
@import './partials/backgrounds';
@import './partials/message';
@import './partials/modal';
@import './partials/navigation';
@import './partials/icon';
@import './partials/pagination';
@import './partials/quantity';
@import './partials/labels';
@import './partials/indicator';

//Components
@import './components/agegate';
@import './components/card';
@import './components/countryselector';
@import './components/cta-verticalNav';
@import './components/footer';
@import './components/footer-glo';
@import './components/glo-it-footer';
@import './components/footer-limited';
@import './components/footer-large';
@import './components/form';
@import './components/gloit-header';
@import './components/header';
@import './components/headline';
@import './components/hero';
@import './components/hero-narrow';
@import './components/htmlcontainer-livechat';
@import './components/image';
@import './components/minicart';
@import './components/messagebar';
@import './components/offers';
@import './components/productcard';
@import './components/productcard-globrochureware';
@import './components/productfeatures';
@import './components/producthero';
@import './components/producthero-globrochureware';
@import './components/related-products';
@import './components/cta';
@import './components/video';
@import './components/expander';
@import './components/carousel';
@import './components/tabs';
@import './components/productlisting';
@import './components/productlisting-globrochureware';
@import './components/search';
@import './components/section';
@import './components/sortfilter-glo';
@import './components/sweeps';
@import './components/quiz';
@import './components/quizquestion';
@import './components/gloit-agegate';
@import './components/gloit-hero';
@import './components/gloit-card';
@import './components/gloit-infogloCard';
@import './components/gloit-usp';
@import './components/gloit-blurbs';
@import './components/gloit-cardreview';
@import './components/gloit-banner';
@import './components/gloit-socialgrid';
@import './components/gloit-productCarousel';
@import './components/gloitverticalNav-cta';
@import './components/gloittextlist-plainpage';
@import './components/gloitaccordionlist-plainpage';
@import './components/gloit-engraving';
@import './components/gloit-tabs';
@import './components/gloitsection-engraving';
@import './components/gloit-reviews';
@import './components/gloitform-reviews';
@import './components/gloitplp-hero';
@import './components/gloitinsiders-hero';
@import './components/gloit-sortfilter';
@import './components/gloit-productlist';
@import './components/gloitspecifications-pdp';
@import './components/gloitinthebox-pdp';
@import './components/gloitproducthero-pdp';
@import './components/gloitartandmore-landing';
@import './components/gloit-map';
@import './components/gloitimage-carousel.scss';
@import './components/gloit-cardNews';
@import './components/gloitartandmore-hero';
@import './components/gloitportaunamico-landing';
@import './components/gloitportaunamico-hero';
@import './components/gloitbreakers-hero';
@import './components/gloitbreakers-content';
@import './components/gloitmclaren-hero';
@import './components/gloit-video-section';
@import './components/gloitmclaren-content.scss';
@import './components/gloitdiscover-hero.scss';
@import './components/gloitdiscover-landing.scss';
@import './components/gloit-hitech.scss';
@import './components/gloit-personalizza.scss';
@import './components/gloit-neotobacco.scss';
@import './components/gloit-acquistahyper.scss';
@import './components/gloit-infotabacco.scss';
@import './components/gloit-puntivendita.scss';
@import './components/gloit-discovervideo.scss';
@import './components/gloitalternativa-landing.scss';
@import './components/gloit-alternativa.scss';
@import './components/gloit-confronto.scss';
@import './components/gloit-analisi.scss';
@import './components/gloit-iscrizione.scss';
@import './components/gloit-sidecard';
@import './components/gloitnewsletter-form';
@import './components/gloitnewsletter-banner';
@import './components/gloit-minicart';
@import './components/gloit-storelocator';
@import './components/gloitsearch-storelocator';
@import './components/gloitmap-storelocator';
@import './components/gloitmentholban-landing';
@import './components/gloitinsiders-landing';
@import './components/gloit-coins';
@import './components/gloit-faqcoins';
@import './components/gloitinsiderstab-landing';
@import './components/gloitinsiderstabaccordion-landing';
@import './components/gloitinsiderstabalternative-landing';
@import './components/gloit-forgotpassword';
@import './components/gloit-createpassword';
@import './components/gloit-signup';
@import './components/gloit-registrationsalesforce';
@import './components/gloit-signin';
@import './components/gloitmclaren-text';
@import './components/gloit-bloglist';
@import './components/gloit-signin';
@import './components/gloit-signup';
@import './components/gloit-portaunamico';
@import './components/gloit-entraclubmodal.scss';
@import './components/gloit-loyaltyhistory';
@import './components/gloit-cardloyaltyinfo';
@import './components/gloit-cardloyaltyinfosimple';
@import './components/gloit-loyaltysuccessmodal';
@import './components/gloit-redeemmodal';
@import './components/gloit-surveymodal';
@import './components/gloit-mgmmodal';
@import './components/gloit-tierinfocard';
@import './components/gloit-imagegridcard';
@import './components/gloit-telegramcard';
@import './components/gloit-stickycard';

@import './components/gloit-paymentmethod';
@import './components/gloitadd-paymentmethod';
@import './components/gloitedit-paymentmothod';
@import './components/gloit-addressbok';
@import './components/gloitadd-addressbook';
@import './components/gloitedit-addressbook';
@import './components/gloit-orderhistory.scss';
@import './components/gloit-orderhistorypage.scss';
@import './components/gloit-orderdetailspage.scss';
@import './components/gloit-orderdetails.scss';
@import './components/gloit-itemdetail.scss';
@import './components/gloit-orderinformation.scss';
@import './components/gloit-accountoverview';
@import './components/gloit-editdetails';
@import './components/gloit-editpreferences';
@import './components/gloit-deleteaccount';
@import './components/gloit-changepassword';
@import './components/gloit-registerdevice';
@import './components/gloit-blogdetail';
@import './components/gloit-forgotpassword';
@import './components/gloit-createpassword';
@import './components/gloit-riscattacoin';
@import './components/gloit-insidersshop';
@import './components/gloitinsidersshop-card';
@import './components/gloit-insidersclub';

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: $white;
	color: $blackish;
	text-rendering: optimizeLegibility;

	.bat-wrapper {
		overflow: hidden;

		@include media-breakpoint-up(lg) {

			.bat {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: $max-width-xxl;

				&.full-width {
					max-width: none;
				}
			}

			bat-header-default,
			bat-header-limited,
			bat-header-glo,
			bat-header-gloit,
			bat-glo-it-header,
			bat-footer-velo,
			bat-footer-glo, 
			bat-footer-gloit,
			bat-infoglocard-gloit,
			bat-usp-gloit,
			bat-banner-gloit,
			bat-socialgrid-gloit,
			bat-productcard-gloithome,
			bat-carousel-gloitproductcard,
			bat-engraving-gloit,
			bat-reviewslist-gloit,
			bat-productfeatures-gloitspecifications,
			bat-producthero-gloit,
			bat-hero-gloit,
			bat-artandmore-gloit,
			bat-portaunamico-gloit,
			bat-confronto-gloit,
			bat-card-gloitnewsletterbanner,
			bat-card-gloitnewsletterpopup,
			bat-storelocator-gloit,
			bat-searchstorelocator-gloit,
			bat-mapstorelocator-gloit,
			bat-bloglist-gloit,
			bat-orderhistory-gloit,
			bat-orderdetails-gloit,
			bat-itemdetail-gloit,
			bat-blogdetail-gloit{

				&.bat {
					max-width: none;
				}
			}
        }
	}
}

.product-sorting-gloit {
	background-color: #e5e5e5;
	.plp-sort-gloit {
		display: block;
	}
	.filter-section {
		max-width: 100%;
		padding-top: 30px;
		padding-bottom: 30px;
		justify-content: center;

		&.category-slider{
			&.hidden{
				display: none;
			}
		}

		&.categories-accordion {
			max-width: unset;
			z-index: 899;
		}
		
		@include media-breakpoint-up(md){
			align-items: center;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			background-color: $charcoal;
		}
		background-color: $white;
		.categories-title-container{
			padding: 0 20px 0 20px;
			@include media-breakpoint-up(lg) {
				border-right: 2px solid $concrete;
				padding-bottom: 0px;
			}
			.categories-title{
				font-size: 17px;
				color: $charcoal;
				@include media-breakpoint-down(md) {
					color: $white;
					}
			}
		}
		.container-slider {
			padding: 10px;
			background-color: $white;
			@include media-breakpoint-down(md) {
				background-color: $charcoal;
			}

			.slider-menu {
				
				.slider-item{
					&.selected{
						button{
							background-color: $brand-black;
							b{
								color: $white;
							}
						}

						@include media-breakpoint-down(md){
							button{
								background-color: $white;
								b{
									color: $brand-black;
								}
							}
						}
					}
				}

				.container-button {
					border: 2px solid $charcoal;
					@include media-breakpoint-down(md) {
						border: 2px solid $white;
					}
					line-height: 26px;
					min-width: 13px;
					border-radius: 13px;
					padding-top: 5px;
					padding-bottom: 5px;
					display: inline-flex;

					.button-text-size {
						font-size: 12px;
						font-weight: 800;
						line-height: 15px;
						text-align: center;
						color: $charcoal;
						@include media-breakpoint-down(md) {
							color: $white;
						}
					}
				}
			}
		}
		.uk-slider-container-offset {
			margin: -11px -25px -39px -25px;
			padding: 11px 30px 35px 30px;
		}

		.uk-grid > * {
			padding-left: 10px;
			@include media-breakpoint-up(md) {
				padding-left: 14px;
			}
		}

		.uk-container{
			margin-left: 0;
   			margin-right: 0;
			@include media-breakpoint-up(md) {
				margin-left: 0;
   				margin-right: 0;
			}

			.slidenav-previous{
				background: linear-gradient(90deg,#3a3a3a,#3a3a3a,#3a3a3a, transparent);
			}
			.slidenav-next{
				background: linear-gradient(90deg, transparent,#3a3a3a,#3a3a3a,#3a3a3a);
			}
		}

		
	}
	.categories-accordion{

		.uk-container{
			max-width: none;
		}
		.slider-menu{
			display: block;
			justify-content: center;
			.slider-item{
				margin-bottom:20px;
			}
		}

		.uk-accordion-title{
			display: block;
			&::before {
				content: "";
				width: 1.4em;
				height: 1.4em;
				margin-right: 20px;
				float: right;
				background-image: url('/content/dam/glo-it/images/plp/chevron-open.svg');
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}

		.uk-open{
			.uk-accordion-title{
				display: block;
				&::before {
					content: "";
					width: 1.4em;
					height: 1.4em;
					margin-right: 20px;
					float: right;
					background-image: url('/content/dam/glo-it/images/plp/chevron-close.svg');
					background-repeat: no-repeat;
					background-position: 50% 50%;
				}
			}
		}

		padding-top: 15px;
		padding-bottom: 15px;

		ul,li {
			margin-bottom: 0;
		}
	
	}
}	

bat-sortfilter-gloit.product-sorting-gloit {
	&.bat {
		display: block;
		// sass-lint:disable no-important
		margin-left: -3.5px !important;
		margin-right: -3.5px !important;
		max-width: unset !important;
	}
	.bat-plp-filter-container {
		margin: 0 auto;
	}

	.bat-filter {
		-moz-box-shadow: 7px 6px 11px -1px $dark-30;
		-webkit-box-shadow: 7px 6px 11px -1px $dark-30;
		background-color: $white;
		box-shadow: 7px 6px 11px -1px $dark-30;

		z-index: $modal-zindex;

		&-overlay {
			background: $charcoal;
			bottom: 0;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: 0;
			transition: 0.5s;
			z-index: $modal-zindex;
			&--open {
				opacity: 0.33;
				pointer-events: all;
			}
		}

		&-wrapper {
			height: 100%;
			overflow-y: auto;
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		&-closebutton {
			right: 1rem;
		}

		&-applybutton {
			padding: 20px 0 20px 20px;
			border-top: 1px solid $gray;
			background-color: $white;
			margin-top: auto;
			flex-shrink: 0;

			@include media-breakpoint-down(md) {
				padding: 10px;
			}

			button {
				&[data-parameters="applyButton"] {
					background-color: $purple;

					&:hover {
						background-color: $purple-hover;
					}
				}

				@include media-breakpoint-down(md) {
					padding: 10px;
					width: 90%;
					margin: 0 auto;
				}
			}
		}

		&-collapse {
			border-top: 1px solid $dark-gray;
			padding: 1rem 0;

			&:hover {
				cursor: pointer;
			}

			i {
				height: 24px;
				width: 24px;

				&:before {
					font-size: 24px;
				}
			}

			.title {
				@include font-rem(18px, 18px);
				text-transform: uppercase;
				font-weight: 700;
			}

			&-container {
				flex-grow: 1;
				overflow: auto;
				padding: 20px;
				margin-top: 0;

				@include media-breakpoint-up(lg) {
					padding: 20px 40px;
				}

				.group-title {
					@include font-rem(24px, 24px);
					border-bottom: 0;
					font-weight: 700;
					padding: 1rem 0;
				}
			}

			&-items {
				border: 0;
				padding: 0 0 2rem;

				ul {
					li {
						label {
							@include font-rem(16px, 16px);
							align-content: center;
							align-items: center;
							display: flex;
							margin-bottom: 15px;

							.count {
								@include font-rem(14px, 14px);
								color: $dark-gray-50;
								margin-left: 5px;
							}

							input {
								display: inline;
								margin-right: 12px;
								position: relative;
								width: auto;

								&:before {
									background-color: $white;
									color: $black;
									content: "\e977";
									display: inline-block;
									font-family: "icomoon";
									font-size: 18px;
									height: 18px;
									margin: -4px 0 0 -4px;
									position: relative;
									width: 18px;
								}

								&:checked {
									&:before {
										color: $black;
										content: "\e979";
										display: inline-block;
									}

									+ span {
										@include font-rem(14px, 14px);
										color: $charcoal;
									}
								}

								+ span {
									@include font-rem(14px, 14px);
								}
							}
						}
					}
				}
			}
		}
	}

	.reset-button,
	.clear-button {
		align-items: center;
		color: $gray-dark;
		display: flex;
		font-weight: 700;
		text-transform: uppercase;
	}

	.reset-button {
		i {
			&:before {
				font-size: 24px;
			}
		}
	}

	.bat-plp-button-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 auto -10px;
		padding: 0 10px;
		position: relative;
		max-width: 1200px;
	}

	.bat-filter-button {
		display: flex;
		margin: 16px;

		[data-parameters="filterButton"] {
			@include font-rem(15px, 15px);
			align-items: center;
			color: $charcoal;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;

			&:hover {
				color: $orange;

				i {
					&:before {
						color: $orange;
					}
				}
			}

			i {
				margin-right: 5px;

				&:before {
					color: $charcoal;
					font-size: 24px;
				}
			}
		}
	}

	.bat-filter-count {
		display: none;
		position: absolute;
		right: 10px;
	}

	.bat-filter-closebutton {
		button {
			i {
				&:before {
					color: $charcoal;
					font-size: 30px;
				}
			}
		}
	}

	.bat-sort-button {
		display: flex;
		margin: 16px;
		visibility: hidden;

		[data-parameters="sortButton"] {
			@include font-rem(15px, 15px);
			align-items: center;
			color: $charcoal;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;

			&:hover {
				color: $orange;

				i {
					&:before {
						color: $orange;
					}
				}
			}

			i {
				&:before {
					color: $charcoal;
				}
			}
		}

		i {
			&:before {
				color: $charcoal;
				font-size: 24px;
			}

			&[id="sortButtonIconOpen"] {
				display: none;
			}

			&[id="sortButtonIconClosed"] {
				display: flex;
			}
		}

		&.open {
			i {
				&[id="sortButtonIconOpen"] {
					display: flex;
				}

				&[id="sortButtonIconClosed"] {
					display: none;
				}
			}
		}

		#sortButtonIcon {
			// sass-lint:disable-line no-ids, id-name-format
			margin-left: 10px;
		}
	}

	.bat-sort-select {
		border: 2px solid $charcoal;
		min-width: 170px;
		z-index: 10;

		button {
			color: $charcoal;
			transition: color 0.1s ease-in;

			&:hover {
				color: $orange;
			}
		}
	}

	.bat-price-slider {
		&-output {
			label {
				input {
					@include font-rem(14px, 14px);
					background-color: $concrete;
					border: 0;
					color: $charcoal;
					padding: 10px;
				}
			}
		}

		&-range {
			input {
				&[type="range"] {
					&:focus {
						&::-webkit-slider-runnable-track {
							background: $purple-gradient;
						}
					}

					&::-webkit-slider-runnable-track {
						background: $purple-gradient;
					}

					&::-webkit-slider-thumb {
						background: $purple-gradient;
					}

					&::-moz-range-track {
						background: $purple-gradient;
					}

					&::-moz-range-thumb {
						background-color: $purple;
					}

					&::-ms-track {
						background-color: $purple;
					}

					&::-ms-thumb {
						background-color: $purple;
					}
				}
			}
		}
	}

	.sortfilter {
		padding-left: 0;
		padding-right: 0;
	}
}

@include media-breakpoint-up(sm) {
	bat-sortfilter-gloit.product-sorting-gloit {
		.bat-filter-button {
			justify-content: flex-end;
			margin: 20px 20px 20px 0;
		}
		.bat-filter-count {
			display: block;
		}
		.bat-plp-button-wrapper {
			justify-content: flex-start;
		}
		.bat-sort-button {
			justify-content: flex-start;
			margin: 20px 20px 20px 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	bat-sortfilter-gloit.product-sorting-gloit {
		.bat-filter {
			&-wrapper {
				padding: 0;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	bat-sortfilter-gloit.product-sorting-gloit {
		.bat-filter {
			&-wrapper {
				padding: 0;
			}
		}
	}
}

.sortfilter-category-error {
	@include font-rem(16px, 28px);
	margin-bottom: 20px;
}


#sort-filter-alternativa{
	background: white;

	.bat-cardnews-gloit--news-img{
		max-height: 360px;
	}
}

bat-sortfilter-gloitnewsgrid {
	max-width: unset !important;
	&.product-sorting-gloit {
		.filter-section {
			background-color: $charcoal;

			.categories-title-container{
				@include media-breakpoint-up(lg) {
					border-right: 2px solid $concrete;
					padding-bottom: 0px;
				}
				.categories-title{
					color: $white;
				}
			}
			.container-slider {
				background-color: $charcoal;

				.slider-menu {
					
					.slider-item{
						
						button {
							text-transform: none;
						}

						&.selected{
							button{
								background-color: $white;
								b{
									color: $brand-black;
								}
							}
						}
					}

					.container-button {
						border: 2px solid $white;

						.button-text-size {
							color: $white;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(md) {
			.bat-plp-slot {
				margin-top: 50px;
			}
		}
	}

}
//TEXT
p {
	.dark,
	&.dark {
		color: $dark-blue;
	}
	
	.dark-gray,
	&.dark-gray {
		color: $dark-gray;
	}
	
	.light,
	&.light {
		color: $white;
	}
}

ol, 
ul {
	
	.dark,
	&.dark {
		color: $dark-blue;
	}
	
	.dark-gray,
	&.dark-gray {
		color: $dark-gray;
	}
	
	.light,
	&.light {
		color: $white;
	}
	
	.no-style,
	&.no-style {
		list-style: none;
		margin: none;
		padding: none;
	}

	.arrow-bullets,
	&.arrow-bullets {
		
		list-style-type: none;
		margin: none;
		padding: none;

		li {
			padding-left: 34px;
			position: relative;

			&:before {
				color: $light-blue;
				content: '\e903';
				font-family: 'icomoon';
				font-size: 24px;
				left: 0;
				position: absolute;
			}
		}
	}
}

.arrow-bullets {
	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		padding-left: 34px;
		position: relative;

		&:before {
			color: $light-blue;
			content: '\e903';
			font-family: 'icomoon';
			font-size: 24px;
			left: 0;
			position: absolute;
		}
	}
}

.list-item-ticks {
				
	li{
		padding-bottom: 17px;
		list-style: url('/content/dam/glo-it/images/infoglo-card/icon-tick.svg');
	}
	
}

.solid-grey {
	border-top: 2px solid #dedede
}

table {
	@include media-breakpoint-down(sm) {
		overflow-x: auto;
		display: block;
	}
	td {
		padding: 8px 10px;
		vertical-align: top;
		border: 1px solid $black;
	}
}

.image-in-text {
	h2 {
		img {
			max-height: 30px;
			@include media-breakpoint-down(sm) {
				max-height: 25px;
			}
		}
	}
}

p.price{
	@include font-rem(30px, 32px);
	font-weight: 800;
	color: $white;
	background-color: $orange;
	padding: 5px;
	width: fit-content;
}

p.price-full{
	@include font-rem(22px, 28px);
	font-weight: 600;
	color: $black;
	text-decoration: line-through;
	padding: 5px;
	width: fit-content;
}

p.price-full-white{
	@include font-rem(22px, 28px);
	font-weight: 600;
	color: $black;
	text-decoration: line-through;
	padding: 5px;
	width: fit-content;
	filter: invert(100%);
}
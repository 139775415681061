.bat-messagebar {
	//-----------------------------------------------------------------| Default
	&--default {
		display: block;
		margin: 0;
		max-height: 0;
		transition: max-height 0.3s ease-in;

		&.active {
			display: block;
			margin-bottom: 0;
			margin-top: 0;
			max-height: unset;
		}

		&-message {
			&-error {
				background-color: $tint-error;
				p {
					color: $red;
				}
			}
			background-color: $cart-banner;
			color: $white;
			font-weight: 700;
			opacity: 0;
			padding: 10px 20px;
			transition: opacity 0.3s ease-in 0.3s;

			.active & {
				opacity: 1;
			}

			p {
				display: inline;
				line-height: 1rem;
			}
		}
	}
}

.bat-engraving-gloit{
    padding-bottom: 120px;

    .invert-engraving-color & {
        .collection-selected,
        .frontText-selected,
        .backText-selected {
            filter: invert(100%);
        }
    } 

    &-container{
        @include media-breakpoint-down(lg){
            display: block;
            margin-left: 0px;
        }

        .collection-selected{
            display: none;
            &.active{
                display: block;
                width: 325px;
                height: 500px;
                position: absolute;
                top: 27%;
                picture{
                    max-width: initial !important;
                    padding: 0;
                    height: 100%;
    
                    @include media-breakpoint-down(sm){
                        width: 70% !important;
                        padding-left: 18px;
                        padding-top: 15px;
                    }

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: 150px;
                    top: 15%;
                    height: 270px;
                }
            } 
            
            &.hidden{
                display: none;
            }
        }

        .frontText-selected{
            display: none;

            &.active{
                display: block;
                position: absolute;
                top: 55%;
                font-size: 45px;

                @include media-breakpoint-down(sm){
                    top: 45%;
                    font-size: 25px;
                }
            }

            &.horizontal{
                transform: rotate(0deg);
            }
            &.vertical{
                transform: rotate(-90deg);
            }

            &.hidden{
                display: none;
            }
        }

        .backText-selected{
            display: none;

            &.active{
                display: block;
                position: absolute;
                padding-left: 30px;
                top: 46%;
                font-size: 45px;

                @include media-breakpoint-down(sm){
                    top: 45%;
                    font-size: 25px;
                }
            }

            &.horizontal{
                transform: rotate(0deg);
            }
            &.vertical{
                transform: rotate(-90deg);
            }

            &.hidden{
                display: none;
            }
        }
    }

    .product-image{
        background-color: #E5E5E5;
        padding-left: 0px;
        height: 980px;
        
        @include media-breakpoint-up(sm) {
            min-width: 640px;
        }

        @include media-breakpoint-down(sm) {
            height: 440px;
        }

        &-container{
            align-items: center;
            position: relative;
            height: 90%;
        }

        &-list {
            height: 100%;
            &-item {
                height: 100%;
                picture{ 
                    height: 100%;
                    width: 260px;
                    @include media-breakpoint-up(md) {
                        width: 585px;
                    }
                    img {
                        height: 100%;
                        object-fit: cover;
                        padding: 30px;

                        @include media-breakpoint-down(sm) {
                            padding: 0;
                        }
                    }
                }
            }
        }
       

        @include media-breakpoint-down(sm){
            width: 100%;
            padding-left: 0px;
        }

        &-prevArrow,
        &-nextArrow{
            border-radius: 100%;
            background-color: $brand-black;
            opacity: 0.3;

            i::before{
                font-size: 20px;
                color: $white;
            }

            @include media-breakpoint-down(sm){
                display: none;
            }

            &.hidden{
                display: none;
            }
        }

        .product-image-list{
            list-style: none;
            padding: 0px;

            @include media-breakpoint-down(sm){
                max-width: 100%;
            }
            
            &-item{
                display: none;

                &.active{
                    display: block;
                }
            }
        }

        picture{
            padding-top: 60px;
            margin: auto;

            @include media-breakpoint-down(sm){
                padding-top: 0px;
            }
        }

        &-buttons{
            max-width: fit-content;
            margin: auto;
            padding-bottom: 30px;

            .front-button,
            .back-button{
                padding: 13px 40px;
                background-color: $white;
                @include font-rem(12px, 14px);
                font-family: $primary-font;
                font-weight: 600;
                letter-spacing: 0.5px;
                color: #6B6B6B;

                &.selected{
                    background-color: $black;
                    color: $white;
                }
            }

            &.hidden{
                display: none;
            }
        }

        &-colors{
            max-width: fit-content;
            margin: auto;
            padding-bottom: 30px;
            text-align: center;

            .colors-container{
                margin-bottom: 10px;
                i::before{
                    font-size: 20px;
                }
            }

            .color{
                padding: 15px;
                border-radius: 100%;
                margin-left: 5px;
                margin-right: 5px;
            }

            .label{
                @include font-rem(14px, 34px);
                padding-top: 5px;

                @include media-breakpoint-down(sm){
                    display: none
                }
            }

            &.hidden{
                display: none;
            }
        }
        
    }

    .product-engraving{
        padding-left: 0px;

        @include media-breakpoint-down(sm){
            width: 100%;
            padding-left: 0px;
        }

        &-info{
            padding: 20px 100px 0px;
            align-items: center;

            @include media-breakpoint-down(sm){
                padding: 20px 20px;
            }

            &-name {
                max-width: 85%;
            }

            &-name,
            &-price{
                font-family: $primary-font;
                @include font-rem(20px, 34px);
                font-weight: 600;

                @include media-breakpoint-down(sm){
                    @include font-rem(18px, 27px);
                    width: 70%;
                }
            }

            &-price{
                margin-right: 10px;

                @include media-breakpoint-down(sm){
                    margin-right: 0px;
                }
            }

            .bat-icon{
                &::before{
                    font-size: 20px;

                    @include media-breakpoint-down(sm){
                        font-size: 16px;
                    }
                }

                @include media-breakpoint-down(sm){
                    padding-bottom: 4px;
                }
            }
        }

    }
}
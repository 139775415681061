bat-card-gloittierinfo {
	height: 100%;
}

.tier-info-card{
	display: flex;
	flex-direction: column;
	padding: 0px;
	background: $white;
	border: 2px solid #5F73BA;
	box-shadow: 8px 8px 0px #D5DDEC;
	height: 100%;
	
	.list-container {
		gap: 18px;

		.circle-levels {
			justify-content: center;
			align-items: center;
			gap: 6px;
			padding-top: 24px;
			.circle-level {
				width: 8px;
				height: 8px;
				background: #DEDEDE;
				border-radius: 100%;
				color: transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				
				&.active {
					color: $white;
					width: 20px;
					height: 20px;
					
					&.trendsetter {
						background: linear-gradient(135deg, #B4C7E7 0%, #90AFDD 51.04%, #6D96D1 100%);
					}

					&.hero {
						background: linear-gradient(135deg, #3FA4FC 0%, #2B82C4 51.04%, #196EAF 100%);
					}

					&.leader {
						background: linear-gradient(135deg, #000AE3 0%, #18207F 51.04%, #0B1047 100%);
					}
				}

			}
		}

		.level-info {
			text-align: center;
			color: #2A2C2C;

			&-title {
				font-weight: 600;
				font-size: 20px;
				line-height: 28px;
			}

			&-description {
				font-weight: 300;
				font-size: 14px;
				line-height: 17px;
			}
		}

		.level-banner {
			padding: 12px 16px;
			text-align: center;
			font-weight: 700;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			color: $white;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

			&.trendsetter {
				background: linear-gradient(135deg, #B4C7E7 0%, #90AFDD 51.04%, #6D96D1 100%);
			}

			&.hero {
				background: linear-gradient(135deg, #3FA4FC 0%, #2B82C4 51.04%, #196EAF 100%);
			}

			&.leader {
				background: linear-gradient(135deg, #000AE3 0%, #18207F 51.04%, #0B1047 100%);
			}
		}
		
		.element-list{
			flex-direction: column;
			
			.single-element{
				padding: 16px 12px;
				gap: 12px;
				border-bottom: 1px solid #D5DDEC;

				&:last-child {
					border-bottom: unset;
				}
			}

			.icon{
				min-width: 24px;
			}
			.description{
				font-weight: 700;
				font-size: 14px;
				line-height: 20px;
				color: #232323;
			}
		}
	}
}
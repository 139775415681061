// File:           _variables.scss
// Description:   Variables Styles
/*! Style Variables
sm
sm light
sm dark-gray
lg
lg light
lg dark-gray
xl
xl light
xl dark-gray
subtitle
subtitle light
subtitle dark-gray
headline6 light
headline6-color1
bullet--numbered
bullet--icon-place
bullet--icon-embrace
card-price
card-&more
*/

//Colors
$black: #000;
$red-10: rgba(192, 43, 31, 0.1);
$orange: #cd4a04;
$ligth-orange: #FF9760;
$orange-2: #f19c1a;
$orange-3: #f5b90e;
$orange-10: rgba(239, 123, 49, 0.1);
$yellow: #f9d602;
$green: #007955;
$green-10: rgba(0, 121, 85, 0.1);
$teal: #008996;
$teal-light: #1ecad3;
$teal-dark: #018993;
$blue-light: #00aed6;
$brand-black: #2a2a2c;
$charcoal: #3a3a3a;
$white: #fff;
$blue: #2f539f;
$blackish: #1d1d1b;
$lilac: #c4b3cc;

$orange-cta-gradient-start: $orange;
$orange-cta-gradient-end: #ed7d30;
$orange-cta-gradient-hover-start: #ba4304;
$orange-cta-gradient-hover-end: #d7722d;

//Gray Palette
$gray-dark: rgba(59, 59, 60, 1);
$gray-dark-10: rgba(59, 59, 60, 0.1);
$gray-dark-30: rgba(59, 59, 60, 0.3);
$gray-dark-50: rgba(59, 59, 60, 0.5);
$gray-dark-90: rgba(59, 59, 60, 0.9);
$gray-medium: #d1d3d4;
$gray-light: #f2f2f2;
$button-text: #3b3b3c;
$font-color: #333;

//Gradients
$teal-gradient: linear-gradient(90deg, $teal-dark, $teal-light);
$main-gradient: linear-gradient(90deg, $orange-2 0%, $yellow 100%);
$tooltip-stroke-gradient: linear-gradient(90deg, $orange-2 0%, $orange-3 100%);
$cta-primary-gradient: linear-gradient(
	267.63deg,
	$orange-cta-gradient-start 0.65%,
	$orange-cta-gradient-end 100%
);
$cta-primary-gradient-hover: linear-gradient(
	267.63deg,
	$orange-cta-gradient-hover-start 0.65%,
	$orange-cta-gradient-hover-end 100%
);

//2.0 colors
$light-blue: rgba(47, 83, 159, 0.2);
$purple: #5862bf;
$purple-hover: #5058a6;
$green: #4f868e;
$red: #e00830;
$dark-blue: #041e42;
$dark-blue-hover: #143564;
$dark-blue-90: rgba(4, 30, 66, 0.9);
$dark-blue-80: rgba(4, 30, 66, 0.8);
$extra-dark-blue: #001734;
$bronze: #e98852;
$silver: #b4c7d8;
$gold: #fcb549;
$banner-background: $blue;
$button-background: #e6eff9;
$cart-banner: #4cae04;

//2.0 Tints
$tint-error: #fdf3f5;
$tint-points: #eeeff9;
$tint-multibuy: #e6f6f9;

//2.0 Gray Palette
$concrete: #efefef;
$light-gray: #dedede;
$gray: #aaa;
$dark-gray: #6b6b6b;
$dark-gray-10: rgba(107, 107, 107, 0.1);
$dark-gray-20: rgba(107, 107, 107, 0.2);
$dark-gray-30: rgba(107, 107, 107, 0.3);
$dark-gray-40: rgba(107, 107, 107, 0.4);
$dark-gray-50: rgba(107, 107, 107, 0.5);
$dark-gray-60: rgba(107, 107, 107, 0.6);
$dark-gray-70: rgba(107, 107, 107, 0.7);
$dark-gray-80: rgba(107, 107, 107, 0.8);
$dark-gray-90: rgba(107, 107, 107, 0.9);

//2.0 Gradients
$bronze-gradient-1: #b3481a;
$bronze-gradient-2: rgba(244, 140, 56, 0);
$bronze-gradient: linear-gradient(
	217deg,
	$bronze-gradient-1 40.9%,
	$bronze-gradient-2 85.02%
);
$silver-gradient-1: #808aab;
$silver-gradient-2: rgba(204, 226, 234, 0);
$silver-gradient: linear-gradient(
	217deg,
	$silver-gradient-1 40.9%,
	$silver-gradient-2 85.02%
);
$gold-gradient-1: #f48c38;
$gold-gradient-2: rgba(244, 140, 56, 0);
$gold-gradient: linear-gradient(
	217deg,
	$gold-gradient-1 40.9%,
	$gold-gradient-2 85.02%
);
$purple-gradient: linear-gradient(90deg, $purple-hover, $purple);

// Typography
$base-font: 16px; // 1rem = 16px;

//Z-indexs
$mini-cart: 999;
$mobile-menu: 9999;
$account: $mobile-menu + 1;
$header-z-index: 980;
$modal-zindex: 999999;

//Header Heights
$header-height-mobile: 70px;
$warning-height: 37px;
$warning-height-mobile: 37px;
$warning-footer-height: 0;
$mobile-menu-height: calc(100vh - #{$header-height-mobile});
$icon-size: 24px;

// Typography
$font-xlg: 24px;
$font-lg: 16px;
$font-md: $base-font; //15px
$font-sm: 12px;
$heading-font: "Mont", sans-serif;
$primary-font: "Mont", sans-serif;
$secondary-font: "Mont", sans-serif;
$age-gate-font: "Trim Poster", sans-serif;
$google-lato: Lato, Arial, Helvetica, sans-serif;
$open-sans: Open Sans, Arial, sans-serif;

// Breakpoints
$grid-breakpoints: (
	xxs: 320px,
	xs: 376px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: $site-max-width,
);

$bp-small-mobile-min: 350px;
$bp-small-mobile-max: 576px;
$bp-mobile-min: 576px;
$bp-mobile-max: 768px;
$bp-tablet-min: 768px;
$bp-tablet-max: 992px;
$bp-small-desktop-min: 992px;
$bp-small-desktop-max: 1200px;
$bp-desktop-min: 1200px;

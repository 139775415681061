.bat-insidersshop-gloit{
    &-title{
        padding: 30px 20px;

        .title-info{
            align-items: center;
            padding-bottom: 15px;

            .title{
                text-transform: uppercase;
                font-size: 34px;

                @media (max-width: $breakpoint-md) {
                    font-size: 24px;
                }
            }

            .total-info{
                padding: 15px;
                background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
                border-radius: 8px;
                font-weight: 500;
                font-size: 14px;
                color: $white;

                @media (max-width: $breakpoint-md) {
                    padding: 10px;
                    font-size: 10px;
                }

                .total{
                    font-weight: 700;
                    margin-left: 15px;

                    @media (max-width: $breakpoint-md) {
                        margin-left: 0px;
                    }
                }
            }
        }

        .subtitle{
            font-weight: 500;
            font-size: 20px;
            width: 75%;

            @media (max-width: $breakpoint-md) {
                text-align: center;
                font-size: 14px;
                width: auto;
            }
        }
    }

    &-cards{
        padding: 0px 0px 30px;
    }
}
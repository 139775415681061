.aem-Grid{
	&.infoglocard{
		padding: 0px 60px;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
	}
}

.bat-infoglocard-gloit{
    
	.uk-grid {
		 margin-left: 0px;
	}
	&-text{
		background-color: $light-gray;
		@include media-breakpoint-down(lg){
			padding: 40px;
			margin-top: 0 !important;
		}
	}
	&-image{
		padding:0;
	}
    .infoglocard{
		
        font-family: $primary-font;
        color: $brand-black;

        &-title,
		&-text,
        &-infolist{
            margin: 0px;
        }

        &-text,
        &-infolist{
            @include font-rem(14px, 20px);
            width: 75%;
        }

        &-title{
            padding-top: 50px;
            padding-left: 100px;
            padding-bottom: 20px;
            @include font-rem(30px, 34px);
            font-weight: 800;
        }

        &-text{
            padding-left: 100px;
            padding-bottom: 25px;
        }

        &-infolist{
            padding-left: 120px;

            li{
                padding-bottom: 17px;
                list-style: url('/content/dam/glo-it/images/infoglo-card/icon-tick.svg');
            }
        }

        &-cta{
            padding-left: 100px;
        }
    }

    @include media-breakpoint-down(xl){

        .uk-flex{
            flex-direction: column-reverse;
        }

        .uk-width-1-2{
            width: 100%;

            .infoglocard{
                &-title{
                    @include font-rem(24px, 28px);
                    padding-left: 20px;
                    padding-top: 0px;
                }

                &-text{
                    padding-top: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: 95%;
                }

                &-infolist{
                    padding: 0px 0px 30px 45px;
                    width: 95%;
                }

                &-cta{
                    padding-left: 0;
                }
            }
        }
        
    }

    h1{
        font-size: 20px;
        font-family: $primary-font;
    }
}
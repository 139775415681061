.gloitbreakers-hero{
    .bat-hero-gloit{
        background-color: $brand-black;

        .bat-hero-gloit-content-container{
            display: flex;
            justify-content: space-between;
            margin: 0px;
            max-width: none;

            .bat-cta-style {
                margin-left: 0;
            }

            @include media-breakpoint-down(sm){
                display: block;
                padding-bottom: 10px;
                .bat-hero-gloit-img{
                    padding: 0px;
                }

                .bat-cta-style{
                    width: 100%;
                    justify-content: center;
                }
            }

            .bat-hero-gloit-img{
                position: initial;
                margin-top: 35px;

                picture{
                    img{
                        width: 700px;
                    }
                }
            }
        }

        .bat-hero-gloit-content{
            padding: 0px;
            margin-top: 100px;
            margin: auto;
            margin-left: 150px;
            margin-right: 150px;

            @include media-breakpoint-down(sm){
                margin: 20px 20px;
                text-align: center;
            }

            .bat-headline{
                margin-left: 0px;
                h2{
                    text-align: left;
                    color: $white;
                    font-weight: 800;
                    @include font-rem(34px, 39px);
                    margin-bottom: 20px;
                    width: 90%;

                    @include media-breakpoint-down(sm){
                        @include font-rem(26px, 32px);
                        width: 100%;
                    }
                }
            }

            .bat-hero-gloit-subheader{
                color: $white;
                font-weight: 500;
                @include font-rem(14px, 20px);
                margin-left: 0px;
                margin-bottom: 20px;
                width: 70%;
                text-align: left;

                @include media-breakpoint-down(sm){
                    width: 100%;
                }
            }
        }
    }

    &.mclaren-hero {
        .bat-hero-gloit-content-container{
            .bat-hero-gloit-img{
                margin-top: 0;
            }

            .bat-cta-style {
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                    justify-content: flex-start;
                    &::after {
                        position: relative;
                        transform: unset;
                    }
                }
            }
        }

        .bat-hero-gloit-content{
            .bat-headline {
                max-width: unset;
                width: 100%;
                .bat-headline-style {
                    width: 100%;
                    text-align: left;
                }
            }   
        }
    }
}
bat-tabs-default {
	.bat-tabs {
		border-top: 0;

		@include media-breakpoint-up(lg) {
			border-top: solid 12px $teal;
		}

		&-indicator {
			border-color: $teal transparent transparent;
			top: 39px;

			@include media-breakpoint-up(lg) {
				top: 0;
			}
		}

		&-list {
			background-color: $gray;

			@include media-breakpoint-up(lg) {
				background-color: transparent;
			}

			&-item {
				&:not(:last-of-type) {
					border-right: 2px solid $white;
				}

				@include media-breakpoint-up(lg) {
					&:not(:last-of-type) {
						border-right: 0;
					}
				}

				&-button {
					box-shadow: inset 0 2px 6px 0 $dark-gray-30;
					padding: 12px 30px;

					@include media-breakpoint-up(lg) {
						box-shadow: none;
					}

					span {
						color: $dark-gray;
						font-family: $primary-font;
						font-size: 16px;
						font-weight: 700;
						letter-spacing: -0.7px;
						line-height: 16px;
						position: relative;

						@include media-breakpoint-up(lg) {
							font-size: 24px;
							line-height: 24px;

							&:after {
								border-bottom: 2px solid $dark-gray;
								bottom: 0;
								content: "";
								left: 0;
								position: absolute;
								transition: width 0.25s ease-in;
								width: 0;
							}
						}
					}

					.active & {
						background-color: $teal;
						border-bottom: 0;

						@include media-breakpoint-up(lg) {
							background-color: transparent;
							color: $dark-gray;
						}

						span {
							color: $white;

							@include media-breakpoint-up(lg) {
								color: $dark-gray;

								&:after {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

body {
	.bat-wrapper {
		bat-tabs-avalanche {
			background-color: $dark-blue;
			padding-bottom: 40px;

			bat-section-default {
				.card {
					display: none;
					&:first-child {
						display: block; // seems to fix weird Tabs bug where multiple Cards are showing
					}
				}
			}

			&.bat {
				@include media-breakpoint-up(lg) {
					height: 525px;
					margin-left: -3.5px;
					min-height: 500px;
					padding-bottom: 120px;
					padding-top: 120px;
				}
			}

			.bat-tabs {
				border-top: 0;

				&-list {
					justify-content: center;
					margin-top: 0;

					&-item {
						&-button {
							border-bottom: 2px solid $dark-gray;
							box-shadow: none;

							padding: 12px;

							@include media-breakpoint-up(md) {
								padding: 12px 20px;
							}

							@include media-breakpoint-up(lg) {
								padding: 12px 30px;
							}

							span {
								color: $white;
								font-family: $primary-font;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: 0.04rem;
								line-height: 14px;
								position: relative;
							}
						}
						&.active {
							button {
								border-bottom: 2px solid $light-blue;

								@include media-breakpoint-up(lg) {
									background-color: transparent;
									color: $light-blue;
								}

								span {
									color: $light-blue;

									@include media-breakpoint-up(lg) {
										color: $light-blue;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

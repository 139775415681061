.glo-it-neotobacco{
	background-color: $concrete;
	.logo{
		max-width:265px;
	}
	.uk-container{
		max-width: $site-max-width;
	}
	.neotobacco-discover{
		@include media-breakpoint-down(sm){
			flex-direction: column-reverse;
		}
		.text{
			max-width: 495px;
			@include media-breakpoint-up(sm){
				margin-right: 20px;
			}
		}
		.title{
			margin-top: 15px;
			margin-bottom: 15px;
			@include media-breakpoint-up(sm){
				font-size: 36px;
				line-height: 36px;
				font-weight: 800;
			}
			@include media-breakpoint-down(sm){
				font-size: 24px;
				line-height: 24px;
			}
		}
		.description{
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
		}
		.description-bold{
			font-size: 16px;
   			line-height: 24px;
    		font-weight: 700;
			margin-top: 15px;
		}
		.img-info-button{
			margin-top:15px;
			margin-bottom: 15px;
			@include media-breakpoint-down(lg){
				justify-content: center;
			}
		}
		.overlapping-img{
			@include media-breakpoint-up(lg){
				margin-top: -50px;
			}
		}
	}
	.uk-card{
		max-width: 170px;
		@include media-breakpoint-up(md){
			max-width: 270px;
		}
		&-body{
			padding: 0;
		}

		.textlabel{
			text-align: left;
			margin-top: 20px;
			font-size: 15px;
		}
	}
	
}
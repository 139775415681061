.bat-createpassword-gloit{
    margin-inline: auto;
    margin-top: 80px;
    width: 500px;

    @include media-breakpoint-down(md){
        margin-inline: 20px;
        width: auto;
    }

    h1{
        text-align: center;
        @include font-rem(30px, 34px);

        @include media-breakpoint-down(md){
            @include font-rem(24px, 28px);
        }
    }

    .bat-form-text{
        text-align: center;
        @include font-rem(14px, 20px);
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .bat-form--create-password{
        &-new-password,
        &-confirm-password,
        &-current-password{
            .error-msg{
                display: none;
            }

            .bat-icon{
                margin-right: 10px;
                margin-left: auto;
                margin-top: -34px;
                cursor: pointer;
            }

            input{
                border-radius: 0px;
                -webkit-appearance: none;
            }

            .password-meter{
                border: 2px solid $concrete;
                @include font-rem(14px, 20px);
                margin-top: 5px;
                padding: 5px 10px;

                &.no-password{
                    background: $white
                }

                &.weak{
                    background: -webkit-linear-gradient(left, #ffafae 25%, white 25%);
                    background: -moz-linear-gradient(left, #ffafae 25%, white 25%);
                    background: -o-linear-gradient(left, #ffafae 25%, white 25%);
                    background: -ms-linear-gradient(left, #ffafae 25%, white 25%);
                    background: linear-gradient(left, #ffafae 25%, white 25%);
                }

                &.medium{
                    background: -webkit-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: -moz-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: -o-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: -ms-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: linear-gradient(left, #ffd6b3 50%, white 50%);                    
                }

                &.strong{
                    background: -webkit-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: -moz-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: -o-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: -ms-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: linear-gradient(left, #c5eeac 75%, white 75%);
                }

                &.very-strong{
                    background: -webkit-linear-gradient(left, #81b562 100%, white 100%);
                    background: -moz-linear-gradient(left, #81b562 100%, white 100%);
                    background: -o-linear-gradient(left, #81b562 100%, white 100%);
                    background: -ms-linear-gradient(left, #81b562 100%, white 100%);
                    background: linear-gradient(left, #81b562 100%, white 100%);
                }
            }
        }

        &-confirm-password, &-current-password{
            .password-meter{
                display: none;
            } 
        }


        &-current-password, &-new-password{
            .frontText-input-error{
                display: none;
            } 
        }


        &-submit{
            text-align: center;

            button{
                width: 300px;

                @include media-breakpoint-down(md){
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        &-messages{
            text-align: center;
            @include font-rem(14px, 20px);
        }

    }
}
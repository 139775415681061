.bat-artandmore-gloit{
    &-description{
        column-count: 2;
        @include font-rem(14px, 20px);
        font-family: $primary-font;
        padding: 70px 100px;
        background-color: $concrete;

        @include media-breakpoint-down(sm){
            column-count: 1;
            padding: 30px 20px;
        }
    }

    &-content{
        .content-buttons{
            max-width: fit-content;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;
            @include media-breakpoint-down(sm){
               max-width: none;
               margin: 0px;
            }


            .content-button{
                width: 200px;
                @include font-rem(13px, 16px);
                font-family: $primary-font;
                font-weight: 700;
                padding: 10px;
                border-color: $blackish;
                &:hover{
                    color: $black
                }
                margin-left: 10px;
                margin-right: 10px;

                @include media-breakpoint-down(sm){
                    @include font-rem(12px, 14px);
                    display: block;
                    width: 100%;
                    margin: auto;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                &.active{
                    background-color: $blackish;
                    color: $white;
                }
            }
        }

        .content-section{
            flex-direction: row-reverse;
            margin-top: 30px;

            @include media-breakpoint-down(md){
                display: block;
            }

            .content-description{
                width: 100%;
                padding: 30px 60px;

                @include media-breakpoint-down(md){
                    padding: 30px 20px;
                }

                .title{
                    @include font-rem(30px, 34px);
                    font-weight: 800;
                    font-family: $primary-font;
                    margin: auto;
                    margin-bottom: 20px;
                    width: 50%;
                   
                    @include media-breakpoint-down(sm){
                        @include font-rem(24px, 28px);
                        width: 100%;
                    }
                }

                .description{
                    @include font-rem(14px, 20px);
                    font-weight: 500;
                    font-family: $primary-font;
                    width: 50%;
                    margin: auto;

                    @include media-breakpoint-down(sm){
                        width: 100%;
                    }
                }
            }

        }
    }
}
.bat-insidersshopcard-gloit{
    background-color: $brand-black;
    color: white;
    padding: 16px;
    margin: 10px 10px;

    &-image{
        a {
            width: 100%;
        }

        .price{
            padding: 8px 16px;
            background-color: $brand-black;
            width: fit-content;
            position: relative;
            top: -40px;
            margin-right: 0px;
            margin-left: auto;

            .price-label{
                font-size: 12px;
                line-height: 15px;
                color: $ligth-orange;
                font-weight: 700;
            }

            .price-value{
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
            }
        }
    }

    &-content{
        flex-direction: column;
        position: relative;
        top: -20px;

        a:hover {
            span {
                text-decoration: none;
                color: $white;
            }
        }

        .category{
            text-transform: uppercase;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 10px;
            font-weight: 700;
        }

        .product-name{
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .bat-cta-style{
        font-size: 14px;
        line-height: 17px;

        @media (max-width: $breakpoint-md) {
            max-width: 100%;
            width: 100%;
        }
    }
}
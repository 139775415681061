.bat-forgotpassword-gloit{
    width: 500px;
    margin: 80px auto 50px;

    @include media-breakpoint-down(md){
        width: auto;
        margin-inline: 20px;
    }

    &-form{
        .bat-form-heading{
            h1{
                @include font-rem(30px, 34px);
                text-align: center;
                margin-bottom: 20px;
    
                @include media-breakpoint-down(md){
                    @include font-rem(24px, 28px);
                }
            }
        }
    
        .bat-form--forgot-password-email{
            input{
                border-radius: 0px;
            }
        }
    
        .bat-form--forgot-password-submit{
            text-align: center;
    
            button{
                @include media-breakpoint-down(md){
                    max-width: 100%;
                    width: 100%
                } 
            }
        }
    
        .subtitle{
            @include font-rem(14px, 20px);
            font-weight: 500;
            text-align: center;
            margin-bottom: 20px;
    
            @include media-breakpoint-down(md){
                @include font-rem(13px, 20px);
            }
        }

        &.hidden{
            display: none;
        }
    }

    &-resend-email{
        text-align: center;

        .title{
            @include font-rem(30px, 34px);
            margin-bottom: 20px;
            text-transform: uppercase;

            @include media-breakpoint-down(md){
                @include font-rem(24px, 28px);
            }
        }

        .subtitle{
            @include font-rem(14px, 20px);
            font-weight: 500;
            margin-bottom: 20px;
    
            @include media-breakpoint-down(md){
                @include font-rem(13px, 20px);
            }
        }

        .cta{
            text-transform: uppercase;
        }

        &.hidden{
            display: none;
        }
    }
}
.bat-insidersclub-gloit{
	width: 87%;
    margin: auto;

    @include media-breakpoint-down(lg){
        width: auto;
        margin: auto 20px;
    }

	.comefunziona-link{
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		letter-spacing: 0.7px;
		align-items: center;

		img{
			margin-left: 10px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 20px;
		}
	}

	&-userLevel{
		background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
		color: white;
		margin-top: 10px;
		align-items: center;
		padding: 30px;

		@include media-breakpoint-down(md){
			flex-direction: column;
		}

		.user-initials{
			width: 10%;
			margin-right: 10px;

			@include media-breakpoint-down(md){
				width: 100%;
				display: flex;
			}

			.initials{
				font-size: 42px;
				font-weight: 700;
				line-height: 46px;
				width: 125px;
				height: 125px;
				margin-inline: -60px;
				background: #D5DDEC;
				color: #18207F;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				@include media-breakpoint-down(md){
					font-size: 34px;
					line-height: 40px;
					margin-inline: 0;
					margin-top: -50px;
					margin-right: 10px;
					width: 108px;
					height: 108px;
				}
			}

			.username{
				font-size: 26px;
				font-weight: 700;
				line-height: 30px;
				text-transform: uppercase;

				@include media-breakpoint-down(md){
					font-size: 24px;
					line-height: 20px;
					width: 50%;
				}
			}
		}

		.user-information{
			width: 70%;

			@include media-breakpoint-down(md){
				width: 100%;
				padding-bottom: 15px;
			}

			.username{
				font-size: 26px;
				font-weight: 700;
				line-height: 30px;
				text-transform: uppercase;

				@include media-breakpoint-down(md){
					font-size: 24px;
					line-height: 20px;
				}
			}

			.level{
				padding: 10px 0px;

				&-trendsetter, &-hero, &-leader{
					border-radius: 10px;
					background: #0B1047;
					border: 2px solid rgba(255, 255, 255, 0.25);
					margin-right: 20px;
					width: 250px;

					&-lock{
						width: 30px;
						background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat white 3px;
						background-position: center;
						border-radius: 8px;
						margin-right: 20px;
						align-self: center;
    					padding: 9px 0px;

						@include media-breakpoint-down(md){
							width: 40px;
							min-width: 29px;
						}

						&-info{
							background: transparent;

							.number{
								background-color: #0B1047;
								width: 17px;
								height: 17px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 100%;
								font-size: 12px;
								line-height: 14px;
								margin-right: 5px;
								margin-left: -10px;
							}
						}
					}

					&-info{
						padding: 10px;
						border-radius: 8px;
						width: 70%;
						height: 36px;

						&-level {
							position: absolute;
						}

						.number{
							background-color: #0B1047;
							width: 17px;
							height: 17px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 100%;
							font-size: 12px;
							line-height: 14px;
							margin-right: 5px;
							margin-left: -18px;
						}
	
						.label{
							text-transform: uppercase;
							font-size: 12px;
							font-weight: 700;
							line-height: 17px;
							letter-spacing: 1.2px;
						}
					}
				}

				&-trendsetter-info {
					background: linear-gradient(135deg, #B4C7E7 0%, #90AFDD 51.04%, #6D96D1 100%);
				}

				&-hero-info {
					background: linear-gradient(135deg, #3FA4FC 0%, #2B82C4 51.04%, #196EAF 100%);
				}
				
				&-leader-info {
					background: linear-gradient(135deg, #000AE3 0%, #18207F 51.04%, #0B1047 100%);
				}
			}

			.level-completion {
				.label {
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					color: $white;
				}

				.percentage {
					font-weight: 600;
					font-size: 14px;
					line-height: 20px;
				}
			}
		}

		.balance{
			width: 20%;
			flex-direction: column;

			@include media-breakpoint-down(md){
				width: 100%;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				padding-top: 15px;
				border-top: 1px solid #D5DDEC;
			}

			.label{
				font-size: 20px;
				font-weight: 600;
				line-height: 28px;

				@include media-breakpoint-down(md){
					width: 50%;
					font-size: 18px;
					font-weight: 600;
					line-height: 28px;
				}
			}

			.balance-value{
				font-size: 42px;
				font-weight: 800;
				line-height: 46px;
				padding: 10px;
				background: #0B1047;
				border-radius: 4px;
				margin: 5px 0px;

				@include media-breakpoint-down(md){
					width: 50%;
					font-size: 28px;
					font-weight: 800;
					line-height: 24px;
				}
			}

			.balance-separator {
				flex-basis: 100%;
				height: 0;
			}

			.balance-total{
				font-size: 12px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0.2px;

				@include media-breakpoint-down(md){
					width: 100%;
					text-align: end;
					margin-top: 10px;
				}
			}
		}
	}

	.mission-container {
		margin-top: 47px;
		margin-bottom: 30px;

		@include media-breakpoint-down(md) {
			margin-top: 24px;
		}

		.mission-card {

			&-content {
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 16px;
				gap: 16px;
				background: #FFFFFF;
				border: 3px solid #18207F;
				filter: drop-shadow(8px 8px 0px #D5DDEC);
			}

			.mission-image-section {
				position: relative;
				width: 100%;

				.mission-image {
					img {
						width: 100%;
					}
				}

				.mission-value {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 8px;
					gap: 15px;
					width: max-content;
					background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);

					.mission-coin {
						font-weight: 700;
						font-size: 18px;
						line-height: 28px;
						text-align: center;
						letter-spacing: 0.2px;
						color: $white;
					}

					.mission-euros {
						font-weight: 700;
						font-size: 12px;
						line-height: 17px;
						text-align: center;
						letter-spacing: 0.2px;
						color: #D5DDEC;
					}
				}

				.mission-type {
					display: flex;
					align-items: center;
					padding: 4px 8px;
					gap: 8px;
					position: absolute;
					background: $white;
					left: 11.61px;
					top: 12px;
					border: 1px solid #3A3A3A;
					border-radius: 8px;
					font-size: 14px;
					line-height: 20px;
				}
			}

			.mission-title-section {
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				.mission-title {
					font-weight: 700;
					font-size: 18px;
					line-height: 28px;
					letter-spacing: 0.2px;
					color: #2A2C2C;
				}
			}
		}
	}
}

#sectionInsidersClub{
	.bat-insidersclub-gloit{
		width: 87%;
		margin: auto;
	}
 }

.icon-mgm {
	&::before {
		content: url('/content/dam/glo-it/images/newloyalty/mgm.svg');
		height: 38px;
	}
}

.icon-redeem {
	&::before {
		content: url('/content/dam/glo-it/images/newloyalty/redeem.svg');
		height: 34px;
	}
}

.icon-survey {
	&::before {
		content: url('/content/dam/glo-it/images/newloyalty/survey.svg');
		height: 35px;
	}
}

.icon-blog {
	&::before {
		content: url('/content/dam/glo-it/images/newloyalty/blog.svg');
		height: 35px;
	}
}

.icon-consent {
	&::before {
		content: url('/content/dam/glo-it/images/newloyalty/consent.svg');
		height: 35px;
	}
}

.icon-reviews {
	&::before {
		content: url('/content/dam/glo-it/images/newloyalty/reviews.svg');
		height: 35px;
	}
}
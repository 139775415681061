.bat-productfeatures-gloitinthebox{
    @include media-breakpoint-down(sm){
        margin-left: 25px;
        margin-right: 25px
    }

    &-title{
        @include font-rem(22px, 28px);
        font-weight: 800;
        text-align: center;
        font-family: $primary-font;
        color: $charcoal;
        max-width: fit-content;
        margin: auto;

        @include media-breakpoint-down(sm){
            @include font-rem(22px, 24px);
            margin-top: 50px;
        }
    }

    &-descriptions{
        justify-content: space-evenly;
        margin-top: 40px;
        margin-bottom: 40px;

        @include media-breakpoint-down(sm){
            display: block;
        }

        .description{
            text-align: center;
            @include media-breakpoint-down(sm){
                display: flex;
            }

            picture{
                @include media-breakpoint-down(sm){
                    max-width: fit-content;
                    margin-right: 50px;
                }
            }

            img{
                height: 120px;
                margin-bottom: 20px;
                object-fit: contain;

                @include media-breakpoint-down(sm){
                    width: 56px;
                    height: 56px; 
                }
            }

            .text{
                @include font-rem(20px, 20px);
                font-family: $primary-font;
                color: $charcoal;
                margin-top: 20px;
            }
        }
    }
}
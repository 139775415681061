.section-page{
    @include media-breakpoint-down(sm){
        padding-top: 0px
    }
}

#faq{
    padding-left: 40px;
    @include media-breakpoint-down(sm){
        padding-left: 0;
    }

    .bat-expander{
        border-bottom:0;
    }

    .bat-headline{
        max-width: 980px;
        @include media-breakpoint-down(sm){
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    .bat-headline-style.headline2{
        @include font-rem(22px, 28px);
        font-weight: 800;
        color: $brand-black;
        font-family: $primary-font;
        padding-bottom: 25px;
        margin-bottom: 0px;
        border-bottom: 1px solid $light-gray;

        @include media-breakpoint-down(sm){
            text-align: center;  
            
        }
    }
}

#productlistinginsidersshop{
    padding-left: 20%;
    @include media-breakpoint-down(sm){
        padding-left: 0;
    }

    .bat-expander{
        border-bottom:0;
    }

    .bat-headline{
        max-width: 980px;
        @include media-breakpoint-down(sm){
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    .bat-headline-style.headline2{
        @include font-rem(22px, 28px);
        font-weight: 800;
        color: $brand-black;
        font-family: $primary-font;
        padding-bottom: 25px;
        margin-bottom: 0px;
        border-bottom: 1px solid $light-gray;
        text-align: center;
       
    }
}
.bat-listaccordion-gloit{
	max-width:980px;
}
.bat-listaccordion-gloit,
.bat-insiderstabaccordion-gloit{
    .title-container{
        margin-bottom: 40px;

        @include media-breakpoint-down(sm){
            margin-bottom: 30px;
        }

        .subtitle-container{
            @include media-breakpoint-down(sm){
                display: block;
            }

            .cta-container{
                @include media-breakpoint-down(sm){
                    width: fit-content;
                    margin: auto;
                }
            }
        }
    }
    .uk-container{
        max-width: none;
        /*@include media-breakpoint-down(lg){
            padding-left: 0px;
            padding-right: 0px;
        }*/
        padding-left: 40px;
        padding-right: 40px;

        @include media-breakpoint-up(sm) {

            padding-left: 0px;
            padding-right: 0px;

        }   
    }

    .title{
        @include font-rem(30px, 34px);
        font-weight: 800;
        color: $brand-black;
        font-family: $primary-font;

        @include media-breakpoint-down(sm){
            @include font-rem(24px, 28px);
            text-align: center;
        }
    }

    .subtitle,
    .subtitle-2{
		font-weight: 500;
        @include font-rem(14px, 20px);
        font-family: $primary-font;
        padding-top: 5px;

        @include media-breakpoint-down(sm){
            text-align: center;
            padding-top: 0px;

        }
    }

    a{
        text-decoration: none;
        padding-left: 20px;
        span{
            font-family: $primary-font;
            @include font-rem(14px, 28px);
            letter-spacing: 1px;
            font-weight: 800;
        }

        @include media-breakpoint-down(sm){
            padding-left: 0px;
        }
    }

    a:hover{
        text-decoration: none;
        color: $charcoal;

        span{
            text-decoration: none;
            color: $charcoal;
        }
    }

    .accordion-title {
        @include font-rem(22px, 28px);
        font-weight: 800;
        color: $brand-black;
        font-family: $primary-font;
        padding-bottom: 25px;
        margin-bottom: 0px;
        border-bottom: 1px solid $light-gray;

        @include media-breakpoint-down(sm){
            text-align: center;  
        }
    }

    .list{
        margin-top: 0px;

        .list-item{
            border-bottom: 1px solid $light-gray;
            margin-top: 0px;

            &.uk-padding{
                padding: 20px 0px;
            }

            .list-item-title{
                font-family: $primary-font;
                @include font-rem(14px, 24px);
                font-weight: 800;
                display: block;
                color: $brand-black;
                padding-left: 0px;

                &::before{
                    content: "";
                    width: 14.4px;
                    height: 14.4px;
                    padding-top: 5px;
                    margin-left: 10px;
                    float: right;
                    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }

            &.uk-open{
                .list-item-title{
                    &::before{
                        content: "";
                        width: 14.4px;
                        height: 14.4px;
                        padding-top: 5px;
                        margin-left: 10px;
                        float: right;
                        background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E);                        
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                    }
                }
            }
            
            .list-item-content{
				margin-right: 2%;
                p{
                    @include font-rem(14px, 20px);
                    color: $charcoal;
                    font-family: $primary-font;
                }
            }

            .list-item-title-indicator{
                color: $gray;
                height: 0px;
            }
        }
    }
}

bat-expander-gloitinsidersaccount {

    .uk-container {
        padding: 0 !important;
    }
}